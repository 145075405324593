import React from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";

const Input = styled(TextField)`
  .MuiInputBase-root {
    color: white;
  }
`;

const InputText = ({ ...props }) => {
  return <Input {...props} />;
};

export default InputText;
