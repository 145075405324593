import {Table, TableHead, Paper, TableRow, TableCell, Button, TableBody} from "@mui/material";
import React, {useState, useContext} from "react";
import {table, modal} from "../../../methods/methods";
import {ContentCopy, NoteAdd} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {TableContainer} from "@mui/material";
import {WebSocketContext} from "../../service/WSContext";
import ModalBudget from "../ModalBudget/ModalBudget";

const TableAllOrcamentos = ({columns, rows, listdata, tableclick = true}) => {
  const navigate = useNavigate();
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [openModalCopy, setOpenModalCopy] = useState(false);
  const [budget, setBudget] = useState({budget_name: "", brand_name: ""});

  const goToOrcamento = id => {
    navigate(`${id}`);
  };

  return (
    <TableContainer component={Paper} elevation={0} sx={{bgcolor: "transparent", marginBottom: 1, boxSizing: "border-box"}} variant="outlined">
      <Table sx={{minWidth: 650}} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => {
              return <TableCell key={i}>{column}</TableCell>;
            })}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody style={{position: "relative"}}>
          {rows.map((row, i) => (
            <TableRow
              hover
              key={i}
              id={row.id}
              sx={{
                "&:last-child td, &:last-child th": {border: 0},
                "cursor": tableclick && "pointer",
                "bgcolor": "#ffffffbc",
              }}>
              <TableCell
                onClick={e => {
                  goToOrcamento(row.uuid);
                }}
                id={row.id}
                component="th"
                scope="row">
                <p>{row.name}</p>
              </TableCell>
              <TableCell
                onClick={e => {
                  goToOrcamento(row.uuid);
                }}
                id={row.id}>
                <p>
                  {row.client_name} | {row.brand_name}
                </p>
              </TableCell>
              <TableCell
                onClick={e => {
                  goToOrcamento(row.uuid);
                }}
                id={row.id}>
                <p>{row.agency_name}</p>
              </TableCell>
              <TableCell
                onClick={e => {
                  goToOrcamento(row.uuid);
                }}
                id={row.id}>
                <p>{table.numberToReais(row.total_value)}</p>
              </TableCell>
              <TableCell id={row.id} sx={{display: "flex", gap: "10px", flexDirection: "row", justifyContent: "end"}} align="right">
                <Button
                  onClick={e => {
                    // handleCopyBudget(row.uuid);
                    setOpenModalCopy(true);
                    setBudget(row);
                  }}
                  sx={{display: "flex", zIndex: 1, gap: "5px", flexDirection: "row", fontSize: 14}}
                  size="small">
                  <ContentCopy sx={{fontSize: 18}} />
                  Copiar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {openModalCopy && (
        <ModalBudget
          open={openModalCopy}
          onClose={e => {
            modal.close(setOpenModalCopy);
          }}
          modalTitle={"Copiar orçamento"}
          copy="true"
          budget={budget}
        />
      )}
    </TableContainer>
  );
};

export default TableAllOrcamentos;
