
import styled from "styled-components";
import colors from "../../../utils/colors.json";
import {Container} from "../ListaClientes/style";

const ContainerListaClausulas = styled(Container)`
background: ${colors["page-todos-clausulas"]};
`;

export {ContainerListaClausulas};

