import React, {useEffect, useState} from "react";
import {AbaContainer} from "./style";
import TablePessoas from "../TablePessoas/TablePessoas";
import TableSupplier from "../TableSupplier/TableSupplier";
import TableDirects from "../TableDirects/TableDirects";
import ModalSupplier from "../ModalSupplier/ModalSupplier";
import ModalService from "../ModalService/ModalService";
import ModalDirects from "../ModalDirects/ModalDirects";
import ModalCenario from "../ModalCenario/ModalCenario";
import TableScenery from "../TableScenery/TableScenery";
import ModalFTE from "../ModalFTE/ModalFTE";
import {modal, table} from "../../../methods/methods";
import {Button} from "@mui/material";
import TableServices from "../TableServices/TableServices";
import {ContainerButtons} from "../ModalCreateSupplier/style";
import AddIcon from "@mui/icons-material/Add";

function AbaProjeto(props) {
  const {children, currentProject, value, ...other} = props;
  const [roles, setRoles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [directs, setDirects] = useState([]);
  const [services, setServices] = useState([]);

  const [openModalFTE, setOpenModalFTE] = useState(false);
  const [openModalDirects, setOpenModalDirects] = useState(false);
  const [openModalCenario, setopenModalCenario] = useState(false);
  const [openModalSupplier, setOpenModalSupplier] = useState(false);
  const [openModalService, setOpenModalService] = useState(false);

  const [inputErrors, setInputErrors] = useState({
    error_1: false,
    error_2: false,
  });

  useEffect(() => {
    setRoles(currentProject.project_resources.filter(resource => resource.resource_kind == 1));
    setSuppliers(currentProject.project_resources.filter(resource => resource.resource_kind == 2));
    setDirects(currentProject.project_resources.filter(resource => resource.resource_kind == 3));
    setServices(currentProject.project_resources.filter(resource => resource.resource_kind == 4));
  }, [currentProject]);

  return (
    <AbaContainer className="container-tabelas">
      <ContainerButtons gap="20px">
        {roles.length == 0 && (
          <Button
            onClick={() => {
              modal.open(setOpenModalFTE);
            }}
            startIcon={<AddIcon />}
            sx={{width: "fit-content", alignSelf: "center", lineHeight: "2em"}}
            variant="contained">
            Pessoa
          </Button>
        )}
        {suppliers.length == 0 && (
          <Button
            onClick={() => {
              modal.open(setOpenModalSupplier);
            }}
            startIcon={<AddIcon />}
            sx={{width: "fit-content", alignSelf: "center", lineHeight: "2em"}}
            variant="contained">
            Fornecedor
          </Button>
        )}
        {/* {directs.length == 0 && (
          <Button
            onClick={() => {
              modal.open(setOpenModalDirects);
            }}
            startIcon={<AddIcon />}
            sx={{width: "fit-content", alignSelf: "center", lineHeight: "2em"}}
            variant="contained">
            Receita/ Despesa
          </Button>
        )} */}
        {services.length == 0 && (
          <Button
            onClick={() => {
              modal.open(setOpenModalService);
            }}
            startIcon={<AddIcon />}
            sx={{width: "fit-content", alignSelf: "center", lineHeight: "2em"}}
            variant="contained">
            Serviço
          </Button>
      )}
      </ContainerButtons>
      {/* {directs.length > 0 && (
        <>
          <p className="titulo-tables">Receitas / Despesas Diretas</p>
          <TableDirects
            {...props}
            currentProject={currentProject}
            setDirects={setDirects}
            handleOpenModalDirects={() => {
              modal.open(setOpenModalDirects);
            }}
            directs={directs}
          />
        </>
      )} */}
      {services.length > 0 && (
        <>
          {" "}
          <p className="titulo-tables">Serviços</p>
          <TableServices
            {...props}
            currentProject={currentProject}
            project_service_total = {currentProject.project_service_total}
            setServices={setServices}
            handleOpenModalServices={() => {
              modal.open(setOpenModalService);
            }}
              services={services}
              //project_service_total = {project_service_total}
          />
        </>
      )}
      {suppliers.length > 0 && (
        <>
          <p className="titulo-tables">Fornecedores</p>
          <TableSupplier
            {...props}
            currentProject={currentProject}
            setSuppliers={setSuppliers}
            handleOpenModalSupplier={() => {
              modal.open(setOpenModalSupplier);
            }}
            suppliers={suppliers}
          />
        </>
      )}
      {roles.length > 0 && (
        <>
          <p className="titulo-tables">Pessoas</p>
          <TableScenery
            {...props}
            sceneryArray={currentProject.project_scenery}
            handleOpenModalCenario={() => {
              modal.open(setopenModalCenario);
            }}
          />
          <TablePessoas
            {...props}
            handleOpenModalFTE={() => {
              modal.open(setOpenModalFTE);
            }}
            roles={roles}
            setRoles={setRoles}
          />
        </>
      )}

      {openModalService && (
        <ModalService
          {...props}
          inputErrors={inputErrors}
          open={openModalService}
          onClose={() => {
            modal.close(setOpenModalService);
          }}
          // currentServices={services}
          // setCurrentServices={setServices}
          modalTitle={"Escolha um serviço"}
        />
      )}

      {openModalSupplier && (
        <ModalSupplier
          {...props}
          inputErrors={inputErrors}
          open={openModalSupplier}
          onClose={() => {
            modal.close(setOpenModalSupplier);
          }}
          currentSuppliers={suppliers}
          setCurrentSuppliers={setSuppliers}
          modalTitle={"Escolha um fornecedor"}
        />
      )}
      {openModalDirects && (
        <ModalDirects
          {...props}
          inputErrors={inputErrors}
          open={openModalDirects}
          onClose={() => {
            modal.close(setOpenModalDirects);
          }}
          modalTitle={"Receitas/Despesas Diretas"}
        />
      )}
      {openModalFTE && (
        <ModalFTE
          {...props}
          open={openModalFTE}
          onClose={() => {
            modal.close(setOpenModalFTE);
          }}
          // handleAddFTE={handleAddFTE}
          modalTitle={"Escolha um recurso"}
        />
      )}
      <ModalCenario
        {...props}
        open={openModalCenario}
        onClose={() => {
          modal.close(setopenModalCenario);
        }}
        // handleAddFTE={handleAddFTE}
        modalTitle={"Cenário Financeiro:"}
      />
    </AbaContainer>
  );
}

export default AbaProjeto;
