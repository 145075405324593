import React, {useEffect, useState} from "react";
import {Container} from "./style";
import {Card, CardContent, Button} from "@mui/material";
import {table} from "../../../methods/methods";
import {Add} from "@mui/icons-material";
import {blue} from "@mui/material/colors";

const CardsProjetos = ({allProjects, setOpenNewModal, setCurrentProject, setopencardproject}) => {
  return (
    <Container>
      {allProjects &&
        allProjects.map((p, key) => {
          return (
            <Card
              key={p.project_uuid}
              variant="outlined"
              onClick={e => {
                setCurrentProject(p.project_uuid);
                setopencardproject(true);
              }}
              className="card-projeto">
              <CardContent>
                <h3 className="card-title">{p.project_name}</h3>

                <div>
                  <h6>Total Projeto</h6>
                  <h3>{table.numberToReais(p.project_total)}</h3>
                </div>
              </CardContent>
            </Card>
          );
        })}
      <Button
        variant="contained"
        startIcon={<Add />}
        className="card-button"
        onClick={e => {
          setOpenNewModal(true);
        }}>
        {" "}
        Projeto
      </Button>
    </Container>
  );
};

export default CardsProjetos;
