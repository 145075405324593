import styled, {css} from "styled-components";
import colors from "../../../utils/colors.json";

const VisaoGeralContainer = styled.div`
  /* overflow-y: hidden; */
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  box-sizing: border-box;
  background: radial-gradient(
        53.91% 79.1% at 50% 55.08%,
        rgba(64, 163, 255, 0.2) 21.76%,
        rgba(255, 255, 255, 0.2) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, #ffffff, #ffffff);
  height: auto;

  .veiculo-wrapper,
  .veiculo-wrapper-coluna {
    display: flex;
  }

  .veiculo-wrapper {
    justify-content: space-between;
  }

  .veiculo-wrapper-coluna {
    flex-direction: column;
    gap: 20px;
  }

  .veiculo-wrapper-coluna:nth-child(1) {
    flex: 1;
  }
  .veiculo-wrapper-coluna:nth-child(2) {
    flex: 3;
    /* padding-top: 10px; */
  }

  @media (max-width: 800px) {
    padding: 20px;

    .veiculo-wrapper {
      flex-direction: column;
    }

    .veiculo-wrapper-coluna:nth-child(1) {
      flex-direction: row;
      gap: 10px;

      div {
        width: 100%;
      }
    }
    .veiculo-wrapper-coluna:nth-child(2) {
      padding-top: 10px;
    }
  }
`;

const PainelGeral = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 15px 30px;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 0px #0000001a;
  border-radius: 10px;
  /* margin: 10px 0; */

  ${(props) =>
    props.size === "fit" &&
    `
    width: fit-content;
  `}

  .painel-bar {
    border-right: 1px solid ${colors["light-gray"]};
    opacity: 0.5;
  }

  @media (min-width: 1600px) {
    padding: 30px;
  }

  @media (max-width: 800px) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
    gap: 5px;
    padding: 0px;
  }
`;

const PainelSugestao = styled.div`
  background-color: ${colors["glassy-green"]};
  border: 2px solid ${colors["green"]};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px 0;

  .painel-bar {
    border-right: 1px solid ${colors["light-gray"]};
    opacity: 0.5;
  }

  @media (min-width: 1600px) {
    padding: 30px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const ItemPainel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  &:nth-last-child(1) {
    border: none !important;
  }

  .painel-value {
    font-size: 1.8em;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    color: ${colors["medium-gray"]};
    white-space: nowrap;
  }

  @media (min-width: 1600px) {
    .painel-value {
      font-size: 2em;
    }
  }

  @media (max-width: 800px) {
    background-color: white;
    box-shadow: none;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px 0px #0000001a;
    border-radius: 10px;
    min-width: 70%;
    .painel-value {
      font-size: 1.5em;
    }
    /* width: 100%; */
  }
`;

const LabelItemPainel = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  .label {
    color: white;
    padding: 2px 10px;
    width: fit-content;
    border-radius: 20px;
    box-sizing: border-box;
    font-size: 14px;
    margin-left: -5px;

    ${(props) =>
      props.label === "faturado" &&
      css`
        background-color: ${colors["orange"]};
      `}
    ${(props) =>
      props.label === "provisao" &&
      css`
        background-color: ${colors["green"]};
      `}
  ${(props) =>
      props.label === "pvesperado" &&
      css`
        background-color: ${colors["green"]};
      `}
  ${(props) =>
      props.label === "bonusvalue" &&
      css`
        background-color: ${colors["blue"]};
      `}
  ${(props) =>
      props.label === "bvesperado" &&
      css`
        background-color: ${colors["blue"]};
      `}
  ${(props) =>
      props.label === "veiculado" &&
      css`
        background-color: ${colors["blue"]};
      `}
  ${(props) =>
      props.label === "investimento" &&
      css`
        background-color: ${colors["purple"]};
      `}
  ${(props) =>
      props.label === "planejado" &&
      css`
        background-color: ${colors["orange"]};
      `}
  }

  .porcentagem {
    font-weight: 600;
    font-size: 18px;

    ${(props) =>
      props.label === "faturado" &&
      css`
        color: ${colors["orange"]};
      `}
    ${(props) =>
      props.label === "provisao" &&
      css`
        color: ${colors["green"]};
      `}
  ${(props) =>
      props.label === "pvesperado" &&
      css`
        color: ${colors["green"]};
      `}
  ${(props) =>
      props.label === "bonusvalue" &&
      css`
        color: ${colors["blue"]};
      `}
  ${(props) =>
      props.label === "bvesperado" &&
      css`
        color: ${colors["blue"]};
      `}
  ${(props) =>
      props.label === "veiculado" &&
      css`
        color: ${colors["blue"]};
      `}
  ${(props) =>
      props.label === "investimento" &&
      css`
        color: ${colors["purple"]};
      `}
  ${(props) =>
      props.label === "planejado" &&
      css`
        color: ${colors["purple"]};
      `}
  }

  @media (min-width: 1600px) {
    .label {
      font-size: 18px;
    }
  }
`;

const VisaoGeralVeiculo = styled(VisaoGeralContainer)`
  /* overflow-y: scroll; */
`

export {
  VisaoGeralContainer,
  PainelGeral,
  ItemPainel,
  LabelItemPainel,
  PainelSugestao,
  VisaoGeralVeiculo
};
