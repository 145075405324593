import React, {useRef, useContext} from "react";
import {TableRow, TableCell, TextField, FormLabel, InputAdornment} from "@mui/material";
import {Delete, KeyboardArrowUp, KeyboardArrowDown} from "@mui/icons-material";
import {WebSocketContext} from "../../service/WSContext";
import CustomTextArea from "../CustomTextArea/CustomTextArea";

const ClausulaBudgetRow = ({idx, clausula, budget_uuid}) => {
  const InputData = useRef({clausulabudget_uuid: "clausula.clausula_uuid"});
  const {current} = useRef({timer: null});
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const styleCell = {
    lineHeight: "1em",
    whiteSpace: "nowrap",
    verticalAlign: "top",
  };

  const autoSave = () => {
    if (current.timer) {
      clearTimeout(current.timer);
    }

    current.timer = setTimeout(() => {
      current.timer = null;
      sendMessageWS({
        method: "update_clausulabudget",
        data: {
          ...InputData.current,
          clausulabudget_uuid: clausula.uuid,
          budget_uuid: budget_uuid,
        },
      });
    }, 1500);
  };

  const handleInput = e => {
    InputData.current = {[e.target.name]: e.target.value};
    autoSave();
  };

  const handleMoveDirection = direction => {
    sendMessageWS({
      method: "update_move_clausulabudget",
      data: {
        clausulabudget_uuid: clausula.uuid,
        direction: direction,
        budget_uuid: budget_uuid,
      },
    });
  };
  const handleRemove = e => {
    sendMessageWS({method: "update_clausulabudget", data: {clausulabudget_uuid: clausula.uuid, delete: true, budget_uuid: budget_uuid}});
  };

  return (
    <TableRow style={styleCell}>
      <TableCell align="left" sx={{minWidth: "100px", borderWidth: "2px", borderColor: "#939393"}} style={styleCell}>
        <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "start", marginBottom: "5px"}}>
          <KeyboardArrowUp
            onClick={() => {
              handleMoveDirection("up");
            }}
            sx={{cursor: "pointer"}}
            fontSize={"large"}
          />
          <KeyboardArrowDown
            onClick={() => {
              handleMoveDirection("down");
            }}
            sx={{cursor: "pointer"}}
            fontSize={"large"}
          />
          <Delete onClick={handleRemove} sx={{cursor: "pointer", marginLeft: "auto", marginRight: "0"}} />
        </div>
        <FormLabel> Nome da Cláusula</FormLabel>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">{idx}</InputAdornment>,
          }}
          fullWidth
          name="name"
          sx={{minWidth: "100%", display: "block", marginBottom: "10px"}}
          size="small"
          defaultValue={clausula.name}
          onChange={handleInput}
        />
        <CustomTextArea label={"Cláusula"} name="text" rows={4} defaultValue={clausula.text} onChange={handleInput} />
      </TableCell>
    </TableRow>
  );
};

export default ClausulaBudgetRow;
