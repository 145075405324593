import {useEffect, useState, useContext, useRef} from "react";
import {Modal, TextField, InputAdornment, Button} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "../ModalCreateSupplier/style";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";
import SelectInput from "../SelectInput/SelectInput";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import getCookie from "../../../utils/GetCookie";

const ModalCreateService = ({open, onClose, current, remove, modalTitle}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [agencies, setAgencies] = useState([]);

  const roleData = useRef({
    service_uuid: 0,
    agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
    service_name: "",
    service_value: "",
  });

  useEffect(() => {
    if (open) {
      sendMessageWS({method: "list_agencies"});
    }
  }, [open]);

  useEffect(() => {
    if (message && message.method == "update_service" && message.data !== null) {
      onClose();
    }
    if (message && message.method == "list_agencies") {
      setAgencies(message.data.agencies);
    }
  }, [message]);

  const handleInput = e => {
    roleData.current[e.target.name] = e.target.value;
    if (e.target.type == "number") {
      roleData.current[e.target.name] = Number(e.target.value);
    }
    if (e.target.type == "checkbox") {
      roleData.current[e.target.name] = e.target.checked;
    }
  };

  const handleUpdateRole = (remove = false) => {
    if (current) {
      sendMessageWS({
        method: "update_service",
        //data: {...roleData.current, delete_service: remove && true, service_uuid: current.uuid, service_value:current.value, service_name:current.name},
        data: {...roleData.current, delete_service: remove && true, service_uuid: current.uuid},
      });
    } else {
      sendMessageWS({method: "update_service", data: roleData.current});
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          name="service_name"
          placeholder="Ex:. Master, Animação, Adaptação..."
          variant="outlined"
          label="Nome do serviço"
          defaultValue={current && current.name}
          onChange={handleInput}
        />
        <SelectInput
          name="agency_uuid"
          onChange={handleInput}
          id="outlined-basic"
          sx={{flexGrow: 1}}
          variant="outlined"
          label="Agência"
          value={getCookie("agencyuuid") && getCookie("agencyuuid")}
          options={agencies}
          inputProps={{readOnly: true}}
        />
        <ContainerButtons>
          <TextField
            type="number"
            name="service_value"
            placeholder="2.500,00"
            variant="outlined"
            sx={{flexGrow: 1}}
            label="Preço"
            defaultValue={current && Number(current.value).toFixed(2)}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$&ensp;</InputAdornment>,
            }}
            onChange={handleInput}
          />
        </ContainerButtons>
        <ContainerButtons>
          {current && (
            <Button
              onClick={() => {
                setOpenDialog(true);
              }}
              variant="contained"
              color="error">
              <DeleteOutlineIcon />
            </Button>
          )}
          <Button
            className="main-btn"
            onClick={() => {
              handleUpdateRole();
            }}
            variant="contained">
            {current ? "Salvar" : "Criar"}
          </Button>
        </ContainerButtons>

        <DialogConfirmDelete
          subject={"esse serviço"}
          open={openDialog}
          handleDelete={() => handleUpdateRole("delete")}
          setDialogDeleteOpen={setOpenDialog}
        />
      </BoxContainer>
    </Modal>
  );
};

export default ModalCreateService;
