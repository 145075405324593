import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";

export default function TabelaVisaoGeral({columns, rows, tableclick = false}) {
  const navigate = useNavigate();
  const handleVeiculo = (e) => {
    if (tableclick) {
      navigate(e.target.id.toLowerCase());
    }
  };

  return (
    <TableContainer component={Paper} sx={{minHeight: "50%"}}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TableRow>
            {columns.map((column, i) => {
              return <TableCell key={i}>{column}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody style={{position: "relative"}}>
          {rows.map((row) => (
            <TableRow
              onClick={handleVeiculo}
              key={row.name}
              id={row.veiculo}
              sx={{
                "&:last-child td, &:last-child th": {border: 0},
                cursor: tableclick && "pointer",
              }}
            >
              <TableCell id={row.veiculo} component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell id={row.veiculo}>{row.veiculo}</TableCell>
              <TableCell id={row.veiculo}>{row.param1}</TableCell>
              <TableCell id={row.veiculo}>{row.param2}</TableCell>
              {/* <TableCell id={row.veiculo}>{row.param3}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
