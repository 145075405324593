import {useEffect, useState, useContext, useRef} from "react";
import {Modal, TextField, Checkbox, FormControlLabel, Button} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "../ModalCreateSupplier/style";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";
import SelectInput from "../SelectInput/SelectInput";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import getCookie from "../../../utils/GetCookie";

const ModalCreateClausula = ({open, onClose, current, remove, modalTitle}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const clausulaData = useRef({
    ...current
  });

  useEffect(() => {
    if (message && message.method == "update_clausula" && message.data !== null) {
      onClose();
    }
  }, [message]);

  const handleInput = e => {
    clausulaData.current[e.target.name] = e.target.value;
    if (e.target.type == "number") {
      clausulaData.current[e.target.name] = Number(e.target.value);
    }
    if (e.target.type == "checkbox") {
      clausulaData.current[e.target.name] = e.target.checked;
    }
  };

  const handleUpdateRole = (remove = false) => {
    if (current) {
      sendMessageWS({
        method: "update_clausula",
        data: {
          ...clausulaData.current,
          clausula_uuid: current.uuid, delete: remove 
        },
      });
    } else {
      sendMessageWS({method: "update_clausula", data: clausulaData.current});
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField name="name" variant="outlined" label="Nome da cláusula" defaultValue={current && current.name} onChange={handleInput} />

        <TextField name="text" label="Cláusula" multiline rows={15} defaultValue={current && current.text} onChange={handleInput} />

        <FormControlLabel name="default" control={<Checkbox defaultChecked={current && current.default} onChange={handleInput} />} label="Default" />

        <p>Marcadores automáticos:</p>
        <p>
          [nome_da_empresa]<br />
          [endereço_da_empresa] <br />
          [cnpj]
        </p>
        <ContainerButtons>
          {current && (
            <Button
              onClick={() => {
                setOpenDialog(true);
              }}
              variant="contained"
              color="error">
              <DeleteOutlineIcon />
            </Button>
          )}
          <Button
            className="main-btn"
            onClick={() => {
              handleUpdateRole();
            }}
            variant="contained">
            {current ? "Salvar" : "Criar"}
          </Button>
        </ContainerButtons>

        <DialogConfirmDelete
          subject={"esse serviço"}
          open={openDialog}
          handleDelete={() => handleUpdateRole("delete")}
          setDialogDeleteOpen={setOpenDialog}
        />
      </BoxContainer>
    </Modal>
  );
};

export default ModalCreateClausula;
