import React, {useEffect, useContext, useState} from "react";
import {Container} from "./style";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";
import TableClientes from "../../components/TableClientes/TableClientes";
import {modal} from "../../../methods/methods";
import ModalCreateClient from "../../components/ModalCreateClient/ModalCreateClient";
import {WebSocketContext} from "../../service/WSContext";
import listbrands from "../../../utils/list_brands.json";
import getCookie from "../../../utils/GetCookie";

const ListaClientes = () => {
  const [openModalNew, setOpenModalNew] = useState(false);
  const [clients, setClients] = useState([]);
  // const [clients, setClients] = useState(listbrands.data);
  const [filtered, setFiltered] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [agencyUUID, setAgencyUUID] = useState(getCookie("agencyuuid") ? getCookie("agencyuuid") : "");

  useEffect(() => {
    document.title = `BUDGET | Clientes`;
    sendMessageWS({method: "list_brands", data: {agency_uuid: agencyUUID}});
  }, []);

  useEffect(() => {
    setFiltered(clients);
  }, [clients]);

  useEffect(() => {
    if (message && message.method == "list_brands") {
      setClients(message.data);
    }
    if (message && message.method == "update_brand") {
      setClients(message.data);
    }
    if (message && message.method == "update_client") {
      sendMessageWS({method: "list_brands", data:{agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid")}});
      
    }
  }, [message]);

  const handleClickCta = e => {
    modal.open(setOpenModalNew);
  };

  var originalData = [...clients];

  const handleSearch = e => {
    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.client_name.toLowerCase().includes(searchString)) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  return (
    <Container>
      <HeaderGeral
        titulo={""}
        subtitulo={"Clientes"}
        btnCta={"Novo Cliente"}
        placeholder={"Cliente ou Agência..."}
        handleClickCta={() => {
          setOpenModalNew(true);
        }}
        agency={false}
        year={false}
        search={true}
        budget={true}
        handleSearch={handleSearch}
        planilhaCta={false}
      />

      <TableClientes columns={["Cliente", "Marcas"]} rows={clients} listdata={filtered} />

      <ModalCreateClient
        open={openModalNew}
        onClose={e => {
          modal.close(setOpenModalNew);
        }}
        modalTitle={"Novo Cliente"}
      />
    </Container>
  );
};

export default ListaClientes;
