import {useEffect, useState, useRef, useContext} from "react";
import {TextField, InputAdornment, FormControl, InputLabel, MenuItem, Button, Select} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {BoxContainer} from "../ModalProjeto/style";
import {TitleModal} from "../ModalProjeto/style";
import {ModalContainer, VisuallyHiddenInput} from "../ModalFTE/style";
import {WebSocketContext} from "../../service/WSContext";

const ModalDirects = ({open, onClose, budget_uuid, modalTitle, currentProject}) => {
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [type, setType] = useState("");
  const directData = useRef({
    kind: 3,
    project_uuid: currentProject.project_uuid,
    resource_name: "",
    direct: 0,
  });

  const handleAddDirect = () => {
    sendMessageWS({
      method: "update_resource",
      data: directData.current,
    });
    onClose();
  };

  useEffect(() => {
    if (message && message.method == "update_resource" && message.data !== null) {
      onClose();
    }
  }, [message]);

  const handleInput = e => {
    if (e.target.type == "number") {
      directData.current[e.target.name] = Number(e.target.value);
    } else {
      directData.current[e.target.name] = e.target.value;
    }
  };

  return (
    <ModalContainer open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <FormControl>
          <InputLabel id="type-label">Tipo</InputLabel>
          <Select
            labelId="type-label"
            value={type}
            label="Tipo"
            name="direct_kind"
            onChange={e => {
              setType(e.target.value);
              directData.current["direct_kind"] =e.target.value;
            }}>
            <MenuItem value={2}>Receita</MenuItem> 
            <MenuItem value={1}>Despesa</MenuItem>
          </Select>
        </FormControl>

        <TextField onChange={handleInput} name="resource_name" label="Descrição" />
        <TextField
          type="number"
          label="Valor"
          name="direct"
          variant="outlined"
          onChange={handleInput}
          // value={fields.project_receita}
          InputProps={{startAdornment: <InputAdornment position="end">R$&ensp;</InputAdornment>}}
        />
        <Button onClick={handleAddDirect} variant="contained">
          SALVAR
        </Button>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalDirects;
