import React from "react";
import {TableCell, TextField} from "@mui/material";
import cargos from "../../../utils/cargos.json";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const CelulaRecursos = ({role, ftes, setFTEs, id, handleRemove}) => {
  return (
    <TableCell size="small" sx={{minWidth: 80}}>
      <TextField
        fullWidth
        size="small"
        type="text"
        defaultValue={role}
        multiline
        rows={role.length >= 18 ? 2 : 1}
        InputProps={{
          readOnly: true,
          style: {
            minWidth: "100px",
            maxWidth: "200px",
          },
          endAdornment: (
            <DeleteOutlineIcon
              id={id}
              fontSize="small"
              sx={{cursor: "pointer"}}
              onClick={e => {
                handleRemove(e, ftes, setFTEs);
              }}
            />
          ),
        }}
      />
    </TableCell>
  );
};

export default CelulaRecursos;
