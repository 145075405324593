import React, {useRef, useState, useContext, useEffect} from "react";
import {TextField, Checkbox} from "@mui/material";
import {WebSocketContext} from "../../service/WSContext";
import CustomTextField from "../CustomRoleInput/style";

const CustomServiceInput = ({service, project, budget_uuid, name, defaultvalue, ...props}) => {
  const [saveStatus, setSaveStatus] = useState("unset");
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const {current} = useRef({timer: null});

  const [field, setField] = useState({
    [name]: defaultvalue,
  });

  const serviceData = useRef({
    resource_uuid: service.resource_uuid,
    budget_uuid: budget_uuid,
    service_uuid: service.resource_service_uuid,
    project_uuid: project.project_uuid,
    kind: 4, //Level
  });

  useEffect(() => {
    setField({[name]: defaultvalue});
  }, [project, service]);

  const autoSave = () => {
    if (current.timer) {
      clearTimeout(current.timer);
    }

    current.timer = setTimeout(() => {
      current.timer = null;

      sendMessageWS({
        method: "update_resource",
        data: serviceData.current,
      });

      setSaveStatus("saved");
      setTimeout(() => {
        setSaveStatus("unset");
      }, 1000);
    }, 1500);
  };

  const handleInput = e => {
    serviceData.current[e.target.name] = e.target.value;
    setField({[e.target.name]: e.target.value});

    if (e.target.type == "number") {
      serviceData.current[e.target.name] = Number(e.target.value);
    }

    if (e.target.name == "service_markup") {
      var markupWithPoint = e.target.value.replace(",", ".");
      if (e.target.value > 0) {
        serviceData.current = {...serviceData.current, double_taxation: true, service_markup: Number(markupWithPoint) / 100};
        setField(fields => ({...fields, double_taxation: true, service_markup: e.target.value}));
      } else {
        serviceData.current = {...serviceData.current, service_markup: Number(markupWithPoint) / 100};
      }
    }

    if (e.target.type == "checkbox") {
      serviceData.current[e.target.name] = e.target.checked;
      setField(fields => ({...fields, double_taxation: e.target.checked}));
    }

    autoSave();
    setSaveStatus("saving");
  };

  return (
    <>
      {props.type == "checkbox" ? (
        <Checkbox
          onChange={handleInput}
          type="checkbox"
          name={name}
          checked={service.resource_service_markup > 0 ? true : field[name]}
          sx={{minWidth: "fit-content"}}
        />
      ) : (
        <CustomTextField onChange={handleInput} size="small" value={field[name] ? field[name] : ""} name={name} className={saveStatus} {...props} />
      )}
    </>
  );
};
export default CustomServiceInput;
