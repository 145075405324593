import styled from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  box-sizing: border-box;
  background: ${colors["page-todos-clientes"]};
  height: 100%;


  @media (max-width: 800px) {
    padding: 20px;
  }

  @media (min-width: 1500px) and (max-width: 3000px) {
    padding: 30px 15%;
  }
`;

export {Container};
