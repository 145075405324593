import {useContext, useState} from "react";
import {TableRow, InputAdornment, TableCell} from "@mui/material";
import CelulaRecursos from "../CelulaRecursos/CelulaRecursos";
import {WebSocketContext} from "../../service/WSContext";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import {table} from "../../../methods/methods";
import CustomRoleInput from "../CustomRoleInput/CustomRoleInput";

const RoleRow = props => {
  const {budget_uuid, roles, role, setRoles, currentProject, ...other} = props;
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [lastEditedName, setLastEditedName] = useState("false");

  const handleRemove = () => {
    sendMessageWS({
      method: "update_resource",
      data: {
        project_uuid: currentProject.project_uuid,
        resource_uuid: role.resource_uuid,
        delete_resource: true,
        budget_uuid: budget_uuid,
      },
    });
  };

  return (
    <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
      <CelulaRecursos
        role={role.resource_name}
        id={role.resource_uuid}
        {...other}
        handleRemove={e => {
          setOpenConfirmDelete(true);
        }}
      />
      <TableCell align="center">
        <CustomRoleInput
          id={0}
          lasteditedname={lastEditedName}
          setlasteditedname={setLastEditedName}
          role={role}
          project={currentProject}
          budget_uuid={budget_uuid}
          type="checkbox"
          name="role_tbh"
          defaultvalue={role.resource_tbh}
        />
      </TableCell>
      <TableCell align="center">
        <CustomRoleInput
          id={1}
          lasteditedname={lastEditedName}
          setlasteditedname={setLastEditedName}
          role={role}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="months"
          type="text"
          defaultvalue={role.resource_months}
          sx={{minWidth: 20, maxWidth: "100px"}}
        />
      </TableCell>

      <TableCell align="center">
        <CustomRoleInput
          id={2}
          lasteditedname={lastEditedName}
          setlasteditedname={setLastEditedName}
          role={role}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="hrs"
          type="text"
          defaultvalue={role.resource_hrs}
          sx={{minWidth: 20, maxWidth: "100px"}}
        />
      </TableCell>
      <TableCell align="center">
        <p>{role.resource_HRS_total || 0} </p>
      </TableCell>
      <TableCell align="center">
        <CustomRoleInput
          id={3}
          lasteditedname={lastEditedName}
          setlasteditedname={setLastEditedName}
          role={role}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="fte"
          type="text"
          defaultvalue={(role.resource_fte * 100).toFixed(2)}
          sx={{minWidth: 25, maxWidth: "100px"}}
          InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
        />
      </TableCell>
      <TableCell align="center" sx={{cursor: "pointer", whiteSpace: "nowrap"}}>
        <p>{table.numberToReais(role.resource_total / role.resource_HRS_total)}</p>
      </TableCell>
      <TableCell align="center">
        <p>{table.numberToReais(role.resource_total)}</p>
      </TableCell>
      <DialogConfirmDelete open={openConfirmDelete} setDialogDeleteOpen={setOpenConfirmDelete} handleDelete={handleRemove} subject="esse recurso" />
    </TableRow>
  );
};

export default RoleRow;
