import React from "react";
import styled from "styled-components";

const Label = styled.div`
    position: absolute;
    background-color: #ff814b;
    height: 10px;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-radius: 0px 0px 5px 5px ;
    font-weight: 600;
    font-size: 12px;
    z-index: 10;
    display: ${props => props.env === 'production'? 'none' : 'block' };
`

const EnvironmentLabel = () =>{

    return(
        <Label env={process.env.REACT_APP_ENVIRONMENT}>
            <span>{process.env.REACT_APP_ENVIRONMENT.toLocaleUpperCase()}</span>
        </Label>
    )
}

export default EnvironmentLabel