import React, {useEffect, useState} from "react";
import {Container, Header} from "./style";
import {Outlet, useLocation, redirect, useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import MenuLateral from "../../components/MenuLateral/MenuLateral";
import {Squash as Hamburger} from "hamburger-react";
import makeRequest from "../../utils/makeRequest";
import EnvironmentLabel from "../../components/EnvironmentLabel/EnvironmentLabel";
import getCsrfToken from "../../utils/getCsrfToken";
import setCookie from "../../utils/setCookie";

const Home = () => {
  const [currentPage, setCurrentPage] = useState();
  const [menuIsOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    var currentStr = location.pathname;
    setCurrentPage(currentStr.split("/")[1]);
  });

  const checkAuth = () => {
    var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/check_auth/`;

    getCsrfToken()
      .then(res => {
        makeRequest(url, "", "get", "", res.data)
          .then(response => {
            if (response.data.status == 401) {
              navigate("/login");
            }
            if (response.data.status == 200) {
              if (response.data.email !== "admin") {
                try {
                  var initials = response.data.username.split(" ")[0][0] + response.data.username.split(" ")[1][0];
                  window.sessionStorage.setItem("initials", initials);
                } catch (error) {}
              }

              //window.sessionStorage.setItem("vd-budget", response.data.financeiro);
              window.sessionStorage.setItem("ag-uu-id", response.data.agency_group_uuid);

              if (response.data.sysaccess == 0 ) {
                navigate("/nao-autorizado");
              } else {
                if (window.location.pathname === "/") {
                  navigate("/budgets/orcamentos");
                }
                if (response.data.status == 200) {
                  if (response.data.username !== "admin") {
                    var initials = response.data.username.split(" ")[0][0] + response.data.username.split(" ")[1][0];
                    window.sessionStorage.setItem("initials", initials);
                  }

                  window.sessionStorage.setItem("sysaccess", response.data.sysaccess);        
                  window.sessionStorage.setItem("ag-uu-id", response.data.agency_group_uuid);

                  if (window.location.pathname === "/") {
                    navigate("/budgets/orcamentos");
                  }
                  
                }
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    document.title = `BUDGET`;
    checkAuth();
    console.log(`***** ${process.env.REACT_APP_ENVIRONMENT} *****`);
  }, []);

  return (
    <Container env={process.env.REACT_APP_ENVIRONMENT}>
      <EnvironmentLabel env={process.env.REACT_APP_ENVIRONMENT} />
      <MenuLateral env={process.env.REACT_APP_ENVIRONMENT} currentPage={currentPage} open={menuIsOpen} setOpen={setMenuOpen} />
      <div className="outlet-container">
        {window.innerWidth < 800 && <Hamburger toggled={menuIsOpen} toggle={setMenuOpen} color={menuIsOpen ? "#fff" : "#000"} />}
        <Outlet></Outlet>
      </div>
    </Container>
  );
};

export default Home;
