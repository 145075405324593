import React, { useState, useEffect} from "react";
import {Container} from "./style";
import {HeaderAreaProjetos} from "../HeaderAreaProjetos/HeaderAreaProjetos";
import AbaProjeto from "../AbaProjeto/AbaProjeto";

const CardProjeto = props => {
  const {children, project_uuid, projects, budget_uuid, budget, ...other} = props;
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    setCurrentProject(projects.find(p => p.project_uuid == project_uuid));
  }, [project_uuid]);

  useEffect(() => {
    setCurrentProject(projects.find(p => p.project_uuid == project_uuid));
  }, [projects]);

  return (
    <Container>
      {/* {currentProject && <HeaderAreaProjetos currentProject={currentProject} {...props} />} */}
      {currentProject && <AbaProjeto agency_uuid={budget.budget_agency_uuid} currentProject={currentProject} {...props} />}
    </Container>
  );
};

export default CardProjeto;
