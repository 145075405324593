import React, { useEffect } from "react";
import { VisaoGeralContainer, PainelGeral, ItemPainel, LabelItemPainel } from "./style";
import FiltroVeiculoBudget from "../../../components/FiltroVeiculoBudget/FiltroVeiculoBudget";
import TabelaVisaoGeral from "../../../components/TabelaVisaoGeral/TabelaVisaoGeral";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";

function createData(name, veiculo, param1, param2, param3, param4) {
  return { name, veiculo, param1, param2, param3, param4 };
}

const columns = ["Agência", "Veículo", "Sugestão", "BV Esperado", "PV Esperada"];

const rows = [
  createData("FCB Brasil", "Globo", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB SIX", "Facebook", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
  createData("FCB Studio", "RádioVIVA", "R$ 1.000", "R$ 1.000", "R$ 1.000"),
];

const VisaoGeralFinanceiro = () => {

  useEffect(()=>{
    document.title = `BONUS | Visão Geral Financeiro`
  }, [])

  return (
    <VisaoGeralContainer>
      <HeaderGeral titulo={'Visão Geral'} subtitulo={'Financeiro'} />
      <PainelGeral>
        <ItemPainel>
          <LabelItemPainel label={"bonusvalue"}>
            <span className="label"> Bonus Value</span>
          </LabelItemPainel>
          <p className="painel-value">R$ 5.986,74</p>
        </ItemPainel>
        <span className="painel-bar"></span>

        <ItemPainel>
          <LabelItemPainel label={"faturado"}>
            <span className="label"> Faturado</span>
          </LabelItemPainel>
          <p className="painel-value">R$ 5.986,74</p>
        </ItemPainel>
        <span className="painel-bar"></span>

        <ItemPainel>
          <LabelItemPainel label={"provisao"}>
            <span className="label"> Provisão</span>
          </LabelItemPainel>
          <p className="painel-value">R$ 5.986,74</p>
        </ItemPainel>
      </PainelGeral>
      
      <FiltroVeiculoBudget />

      <TabelaVisaoGeral columns={columns} rows={rows} />
    </VisaoGeralContainer>
  );
};

export default VisaoGeralFinanceiro;
