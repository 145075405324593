import styled from "styled-components";
import {TextField} from "@mui/material";
import colors from "../../../utils/colors.json";

const CustomTextField = styled(TextField)`
  border-radius: 5px;
  transition: 0.3s all ease-in;

  ${props =>
    props.className === "unset" &&
    `
        border: none;
        background-color: transparent;
    `}

  ${props =>
    props.className === "saving" &&
    `
        border: 1px solid ${colors.blue} !important;
        background-color: ${colors["super-light-gray"]};
    `}
  ${props =>
    props.className === "saved" &&
    `
        border: 1px solid ${colors.green} !important;
        background-color: #4adaaf73;
    `}
`;

export default CustomTextField;
