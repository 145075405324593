import {useEffect, useContext, useRef, useState} from "react";
import {Box, TextField, Button, ListSubheader, List, ListItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {BoxContainer, TitleModal} from "../ModalProjeto/style";
import {ModalContainer} from "./style";
import {WebSocketContext} from "../../service/WSContext";
import DeleteIcon from "@mui/icons-material/Delete";
import colors from "../../../utils/colors.json";
import {table} from "../../../methods/methods";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {modal} from "../../../methods/methods";

const ModalPermissions = ({budget, open, onClose}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [credentials, setCredentials] = useState([]);

  const handleInput = e => {
    if (e.code === 13 || e.code === "Enter") {
      if (e.target.value !== "" && e.target.value !== " " && e.target.value !== undefined) {
        // setCredentials(credentials => [...credentials, {email: e.target.value}]);
        addAccess(e.target.value);
      }
      e.target.value = "";
    }
  };

  const addAccess = email => {
    sendMessageWS({
      method: "update_add_access",
      data: {
        budget_uuid: budget.budget_uuid,
        email: email,
      },
    });
  };

  useEffect(() => {
    if (message && message.method == "update_add_access") {
      if (message.data.status === 200) {
        onClose();
      }
      if (message.data.status === 400) {
        setOpenDialog(true);
      }
    }
  }, [message]);

  useEffect(() => {
    if (budget) {
     setCredentials(budget.budget_access)
    }
  }, [budget]);

  return (
    <ModalContainer open={open} onClose={onClose}>
      <BoxContainer sx={{minWidth: 250}}>
        <TitleModal>
          Compartilhar orçamento: <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          // error={fieldError.budget_name}
          label="E-mail"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          // defaultValue={edit ? budget.budget_name : ""}
          placeholder="ex:. E-mail"
          onKeyDown={handleInput}
          helperText="Pressione Enter para adicionar"
        />

        <span>Compartilhado com:</span>
        {credentials.length > 0 && (
          <div className="permission-list">
            {credentials.map((credential, i) => {
              return (
                <Box
                  id={i}
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "20px",
                    padding: "10px",
                    boxSizing: "border-box",
                    alignItems: "center",
                    backgroundColor: colors["super-light-gray"],
                    borderRadius: "5px",
                    justifyContent: "stretch",
                  }}>
                  <TextField
                    fullWidth
                    label="E-mail:"
                    variant="standard"
                    value={credential}
                    InputProps={{
                      readOnly: true,
                    }}></TextField>

                  <DeleteIcon
                    onClick={e => {
                      table.removeItem(e, credentials, setCredentials);
                    }}
                    id={i}
                    fontSize="small"
                  />
                </Box>
              );
            })}
          </div>
        )}
        <Dialog open={openDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle>{"Usuário não encontrado."}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              O usuário deve acessar o sistema antes de receber o compartilhamento.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                modal.close(setOpenDialog);
                onClose();
              }}
              autoFocus>
              ENTENDI
            </Button>
          </DialogActions>
        </Dialog>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalPermissions;
