import {Table, TableHead, TableContainer, Paper, TableRow, Checkbox, TableCell, TableBody} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {modal, table} from "../../../methods/methods";
import ModalCreateClausula from "../ModalCreateClausula/ModalCreateClausula";

const TableClausulas = ({columns, rows, listdata, tableclick = true}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentCargo, setCurrentCargo] = useState();

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{bgcolor: "transparent", marginBottom: 1, boxSizing: "border-box"}}>
      <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => {
              return (
                <TableCell style={{width: "fit-content"}} key={i}>
                  {column === " " ? <span>&nbsp;</span> : column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody style={{position: "relative"}}>
          {rows.map((row, i) => (
            <TableRow
              onClick={() => {
                modal.open(setOpenModal);
                setCurrentCargo(row);
              }}
              hover
              key={i}
              id={row.uuid}
              sx={{
                "&:last-child td, &:last-child th": {border: 0},
                "cursor": tableclick && "pointer",
                "bgcolor": "#ffffffbc",
              }}>
              <TableCell id={row.id} component="th" scope="row">
                {row.name}
              </TableCell>



              <TableCell
                style={{width: "fit-content", textAlign: "center"}}
                id={row.id}>
                <EditIcon fontSize="small" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ModalCreateClausula
        open={openModal}
        onClose={() => {
          modal.close(setOpenModal);
        }}
        modalTitle={"Editar Cláusula"}
        edit={true}
        current={currentCargo}
      />
    </TableContainer>
  );
};

export default TableClausulas;
