import {useContext, useState, useRef} from "react";
import {InputAdornment, TableRow, TableCell, TextField, Checkbox, TableBody} from "@mui/material";
import CelulaRecursos from "../CelulaRecursos/CelulaRecursos";
import {WebSocketContext} from "../../service/WSContext";
import {table} from "../../../methods/methods";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import SupplierCarta from "../SupplierCarta/SupplierCarta";
import CustomServiceInput from "../CustomServiceInput/CustomServiceInput";

const ServiceRow = props => {
  const {budget_uuid, services, service, setService, currentProject, ...other} = props;
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleRemove = () => {
    sendMessageWS({
      method: "update_resource",
      data: {
        project_uuid: currentProject.project_uuid,
        resource_uuid: service.resource_uuid,
        delete_resource: true,
        budget_uuid: budget_uuid,
      },
    });
  };

  return (
    <TableRow key={service.resource_uuid} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
      <CelulaRecursos
        handleRemove={e => {
          setOpenConfirmDelete(true);
        }}
        role={service.resource_name}
        id={service.resource_uuid}
        ftes={services}
        setFTEs={setService}
      />

      <TableCell align="right" sx={{minWidth: "150px"}}>
        <CustomServiceInput
          type="number"
          service={service}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="resource_total"
          defaultvalue={Number(service.resource_total)}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$&ensp;</InputAdornment>,
          }}
        />
      </TableCell>
      <DialogConfirmDelete open={openConfirmDelete} setDialogDeleteOpen={setOpenConfirmDelete} handleDelete={handleRemove} subject="esse recurso" />
    </TableRow>
  );
};

export default ServiceRow;
