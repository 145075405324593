import {TableContainer, Table, TableHead, TableRow, TextField, TableCell, Button, Paper, Checkbox, InputAdornment, TableBody} from "@mui/material";
import {table} from "../../../methods/methods";
import {WebSocketContext} from "../../service/WSContext";
import React, {useContext, useEffect, useState, useRef} from "react";
import {Edit} from "@mui/icons-material";
import colors from "../../../utils/colors.json";
import CenarioRow from "../CenarioRow/CenarioRow";

const TableScenery = ({handleOpenModalCenario, sceneryArray, suppliers, ...props}) => {
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [sceneryData, setSceneryData] = useState();

  const styleCell = {
    lineHeight: "1em",
    textAlign: "center",
    minWidth: 10,
    whiteWrap: "nowrap",
  };

  useEffect(() => {
    if (sceneryArray !== null) {
      var sceneryStr = sceneryArray.replace(/'/g, '"');
      setSceneryData(JSON.parse(sceneryStr));
    }
  }, [sceneryArray]);

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead
          style={{
            backgroundColor: "#f8f7f7",
          }}>
          <TableRow>
            <TableCell style={styleCell}>Cenários</TableCell>
            <TableCell style={styleCell} sx={{backgroundColor: "#d5ffe7"}}>
              {sceneryData && sceneryData[0][1][1] && (
                <span>
                  {sceneryData[0][1][1].split("|")[0]} <br />
                  {sceneryData[0][1][1].split("|")[1]}
                </span>
              )}
            </TableCell>
            <TableCell style={styleCell} sx={{backgroundColor: "#a7f2c6"}}>
              {sceneryData && sceneryData[1][1][1] && (
                <span>
                  {sceneryData[1][1][1].split("|")[0]} <br />
                  {sceneryData[1][1][1].split("|")[1]}
                </span>
              )}
            </TableCell>
            <TableCell style={styleCell} sx={{backgroundColor: "#88e0ad"}}>
              {sceneryData && sceneryData[2][1][1] && (
                <span>
                  {sceneryData[2][1][1].split("|")[0]} <br />
                  {sceneryData[2][1][1].split("|")[1]}
                </span>
              )}
            </TableCell>
            <TableCell
              style={styleCell}
              sx={{backgroundColor: colors.blue, gap: "5px", cursor: "pointer", display: "flex", color: "#fff"}}
              onClick={handleOpenModalCenario}>
              <span style={{textAlign: "center", marginLeft: "auto"}}>
                Orçamento <br />
                {sceneryData && sceneryData[3][1][1] && (
                  <span>
                    {sceneryData[3][1][1].split("|")[0]} <br />
                    {sceneryData[3][1][1].split("|")[1]}
                  </span>
                )}
              </span>
              <Edit fontSize="small" sx={{marginLeft: "auto"}} />
            </TableCell>
            {sceneryData && sceneryData[4] && (
              <TableCell style={styleCell} sx={{backgroundColor: "#d1d1d1"}}>
                <span style={{textAlign: "center", marginLeft: "auto"}}>
                  {sceneryData && sceneryData[4][1][1] && <span>{sceneryData[4][1][1]}</span>}
                </span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        {sceneryData && sceneryData[1][1][1] !== "" && (
          <TableBody>
            {sceneryData[0][2] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"Receita"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[2] !== "false") {
                      return <TableCell key={i}> {table.numberToReais(s[2][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )}
            {sceneryData[0][3] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"Impostos"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[3] !== "false") {
                      return <TableCell key={i}> {table.numberToReais(s[3][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )}
            {sceneryData[0][4] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"SRS"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[4] !== "false") {
                      return <TableCell key={i}> {table.numberToReais(s[4][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )}
            {/* TBH comentado */}
            {/* {sceneryData[0][5] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"TBH"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[5] !== "false") {
                      return <TableCell key={i}> {table.numberToReais(s[5][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )} */}
            {sceneryData[0][6] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"Dispesas Diretas"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[6] !== "false") {
                      return <TableCell key={i}> {table.numberToReais(s[6][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )}
            {sceneryData[0][7] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"SRS Incremental"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[7] !== "false") {
                      return <TableCell key={i}>{table.numberToReais(s[7][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )}
            {sceneryData[0][8] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"OH Incremental"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[8] !== "false") {
                      return <TableCell key={i}>{table.numberToReais(s[8][1])}</TableCell>;
                    }
                  })}
              </TableRow>
            )}
            {sceneryData[0][9] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"Margin 1"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[9] !== "false") {
                      return (
                        <TableCell key={i}>
                          {table.numberToReais(s[9][1])} <br />
                          <span className="margin-percentage-tag">{Number(s[9][2] * 100).toFixed(2) + "%"}</span>
                        </TableCell>
                      );
                    }
                  })}
              </TableRow>
            )}
            {sceneryData[0][10] !== "false" && (
              <TableRow>
                <TableCell sx={{fontWeight: 500}}>{"Margin 2"}</TableCell>
                {sceneryData &&
                  sceneryData.map((s, i) => {
                    if (s[10] !== "false") {
                      return (
                        <TableCell key={i}>
                          {s[10] && table.numberToReais(s[10][1])} <br />
                          <span className="margin-percentage-tag">{s[10] && Number(s[10][2] * 100).toFixed(2) + "%"}</span>
                        </TableCell>
                      );
                    }
                  })}
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableScenery;
