import styled from "styled-components";
import colors from "../../utils/colors.json";

const ContratosComp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  .contratos-titulo {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .lista-contratos {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .lista-contratos-item {
    background-color: #e4e4e4;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px 10px;
  }
`;

export { ContratosComp };
