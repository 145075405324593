import styled, {css} from "styled-components";
import {Box} from "@mui/material";

const TitleModal = styled.h2`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
`;

const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;

  ${props =>
    props.gap
      ? css`
          gap: ${props.gap};
        `
      : css`
          gap: 10px;
        `}

  .main-btn {
    width: 100%;
  }
`;

const BoxContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  background-color: white;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 240px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 25px;
    width: 90vw;
  }
`;

export {TitleModal, ContainerButtons, BoxContainer};
