import styled from "styled-components";
import colors from "../../utils/colors.json";

const MenuItemComp = styled.div`
`

const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid #464646;
  width: 100%;
`

const MenuComp = styled.div`
  flex: 1;
  background-color: ${colors["dark-gray"]};
  height: 100%;
  width: fit-content;
  /* min-width: 230px; */
  padding: 20px 15px;
  box-sizing: border-box;
  top: 0;
  gap: 10px;
  white-space: nowrap;
  margin-top: ${props => props.env === 'production'? '0px' : '20px' };

  h3{
    color: white;
    text-align: center;
    margin-bottom: 20px;

    span{
      font-weight: 200;
      letter-spacing: 1px;
    }
  }

  a {
    text-decoration: none; 
    cursor: pointer;
  }
  .login-link, .login-link:visited, .login-link:active {
    color: white;
  }

  &,
  .menu-subitems {
    display: flex;
    flex-direction: column;
  }

  .menu-subitems {
    gap: 5px;
  }

  .menu-item,
  .menu-subitem {
    font-family: "Archivo";
    box-sizing: border-box;
    cursor: pointer;
  }

  .menu-item {
    padding: 0 10px;
    font-weight: 600;
    font-size: 16px;
    color: ${colors["white"]};
  }

  .menu-subitem {
    color: ${colors["light-gray"]};
    border-radius: 30px;
    padding: 10px 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .settings{
    margin-top: auto;
  }

  .menu-subitem:hover {
    background-color: ${colors["medium-gray"]};
  }

  .user-info{
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    font-size: 14px;
    gap: 10px;
  }

  @media (max-width: 800px) {
    position: absolute;
    min-height: 100vh;
    width: 100%;
    top: 0;
    left: -100%;
    padding: 10px;
    z-index: 1051;
    transition: all 0.5s ease;

    .menu-item,
    .menu-subitem {
      font-size: 18px;
    }

    &.open {
      left: 0;
    }
  }
`;

export {MenuComp, MenuItemComp, Divider};
