import React, {useState} from "react";
import {FiltroContainer} from "./style";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {OutlinedInput, InputLabel, FormControl} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const FiltroVeiculoBudget = () => {
  const [isOpenFilter, setOpenFilter] = useState(false);
  const openFilter = () => {
    isOpenFilter ? setOpenFilter(false) : setOpenFilter(true);
  };

  return (
    <FiltroContainer>
      <div className="filtro-titulo">
        <h2>Veículos</h2>
        <Fab color="primary" size="small" aria-label="add">
          <AddIcon />
        </Fab>
        {window.innerWidth < 800 && (
          <FilterAltIcon
            fontSize="large"
            sx={{marginLeft: "auto"}}
            onClick={openFilter}
          />
        )}
      </div>

      <div className={`filtro-campos ${isOpenFilter ? `open` : ``}`}>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">Veículo</InputLabel>
          <OutlinedInput
            size="small"
            id="outlined-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Amount"
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">Budget</InputLabel>
          <OutlinedInput
            size="small"
            id="outlined-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon />
                </IconButton>
                R$&ensp;
              </InputAdornment>
            }
            label="Amount"
          />
        </FormControl>
      </div>
    </FiltroContainer>
  );
};

export default FiltroVeiculoBudget;
