import React from "react";
import styled from "styled-components";
import {FormLabel} from "@mui/material";

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  font-family: "Arial", sans-serif; /* Default font-family */
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allows the textarea to be resized vertically */
  min-height: 100px; /* Minimum height to start with */
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const CustomTextArea = ({label, name, value, defaultValue, onChange, placeholder, rows}) => {
  return (
    <Container>
      {label && <FormLabel>{label}</FormLabel>}
      <StyledTextArea name={name} rows={rows} defaultValue={defaultValue ? defaultValue : ""} value={value} onChange={onChange} placeholder={placeholder} />
    </Container>
  );
};

export default CustomTextArea;
