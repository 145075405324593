import React, {useState, useRef, useContext, useEffect} from "react";
import {Container} from "./style";
import {FormControl, Box, Button, TextField} from "@mui/material";
import {WebSocketContext} from "../../Budget/service/WSContext";
import TableAcessos from "../TableAcessos/TableAcessos";
import ModalAcessos from "../ModalAcessos/ModalAcessos";
import {modal} from "../../methods/methods";
import {Add} from "@mui/icons-material";
import getCookie from "../../utils/GetCookie";

const AccessSettings = ({params}) => {
  const [openModalAccess, setOpenModalAccess] = useState(false);
  const [accessList, setAccessList] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const sysaccess =  window.sessionStorage.getItem("sysaccess");

  useEffect(() => {
    if (message && message.method === "update_sysaccess") {
      if (message.data.status !== 400) {
        setAccessList(message.data);
      }
    }
    if (message && message.method === "sysaccess_list") {
      setAccessList(message.data.access_list);
    }    
  }, [message]);

  useEffect(() => {
    if (sysaccess > 1){
      sendMessageWS({method: "sysaccess_list"});
    }
  }, []);

  return (
    <Container>
      <Box sx={{display: "flex", gap: 1, justifyContent: "start", alignItems: "center"}}>
        {sysaccess > 1 ? <h3>Gerenciar acessos</h3> : <h3>Você não tem acesso a essa sessão.</h3>}
        {/* <TextField sx={{marginLeft: "auto"}} label="Pesquisar acesso" size="small" placeholder="Ex:. email@email.com" /> */}
        {sysaccess > 1 && (
          <Button
            sx={{marginLeft: "auto"}}
            onClick={() => {
              setOpenModalAccess(true);
            }}
            variant="contained"
            startIcon={<Add />}>
            Adicionar acesso
          </Button>
        )}
      </Box>
      
      {sysaccess > 1 && (
        <div className="container-acessos">
          <TableAcessos accessList={accessList} setOpenModalAccess={setOpenModalAccess} />
        </div>
      )}

      {openModalAccess && (
        <ModalAcessos
          open={openModalAccess}
          onClose={() => {
            modal.close(setOpenModalAccess);
          }}
          modalTitle={"Adicionar acesso"}
        />
      )}
    </Container>
  );
};

export default AccessSettings;
