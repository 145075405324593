var methods = {
  getCookie: name => {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  },
  getStatus: status => {
    switch (status) {
      case 1:
        return "em planejamento";
      case 2:
        return "em andamento";
      case 3:
        return "aprovado";
    }
  },
  keepDecimalNotZero: number => {
    if (number) {
      let numStr = number.toString();
      let [integerPart, decimalPart] = numStr.split(".");

      if (decimalPart) {
        decimalPart = decimalPart.replace(/0+$/, "");
      }
      return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
    }
  },
};

var modal = {
  close: setOpen => {
    setOpen(false);
  },
  open: setOpen => {
    setOpen(true);
  },
};

var table = {
  removeItem: (event, data, setState) => {
    var removedIndex = data.findIndex(data => data.id == event.target.id);
    console.log(event.target.id);
    var newData = [...data];
    newData.splice(removedIndex, 1);
    setState(newData);
  },
  numberToReais: value => {
    if (value) {
      return `R$ ${Number(value).toLocaleString("pt-br")}`;
    } else {
      return "R$ 0";
    }
  },
  createData: (name, valormin, valormax, param1, param2) => {
    return {name, valormin, valormax, param1, param2};
  },
};

export {methods, table, modal};
