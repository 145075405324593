import styled from "styled-components";
import colors from "../../utils/colors.json";
import { FormControl } from '@mui/material';

const Container = styled.div`
  background-color: ${colors["dark-gray"]};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginComp = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  gap: 15px;
  box-sizing: border-box;
  width: 250px;

  hr{
    width: 100%;
    border: none;
    border-bottom: 1px solid #ffffff46;
  }

  h3{
    font-size: 2rem;
    text-align: center;
    span{
      font-weight: 100;
      letter-spacing: 1px;
    }
  }

  a{
    cursor: pointer;
  }
`;

export { Container, LoginComp };
