import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors.json";

const CellContainer = styled.div`
  position: relative;
  width: 80%;
  background-color: #e4e4e4;
  border-radius: 5px;
  overflow: hidden;

  .cell-progress {
    background-color: ${colors["light-green"]};
    position: absolute;
    width: ${(props) => props.increase * 100 + "%"};
    height: 100%;
    z-index: 0;
  }

  .cell-value {
    position: relative;
    color: black;
    font-weight: 500;
    z-index: 5;
    padding: 5px 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  @media (max-width: 800px) {
    width: 40vw;
  }
`;

const CellValorDinamico = ({valormin, valormax, increase}) => {
  return (
    <CellContainer increase={increase}>
      <span className="cell-progress"></span>
      <span className="cell-value">
        <span>R$ {valormin}</span> <span>R$ {valormax}</span>
      </span>
    </CellContainer>
  );
};

export default CellValorDinamico;
