import React, {useState, useRef} from "react";
import {Container, LoginComp} from "./style";
import Button from "@mui/material/Button";
import colors from "../../utils/colors.json";
import InputText from "../../components/InputText/InputText";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useNavigate} from "react-router-dom";
import makeRequest from "../../utils/makeRequest";
import getCsrfToken from "../../utils/getCsrfToken";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Login = () => {
  const navigate = useNavigate();
  const loginFormRef = useRef();
  const [fields, setFields] = useState({email: "", password: ""});

  const handleInput = e => {
    setFields(fields => ({...fields, [e.target.name]: e.target.value}));
  };

  const handleLogin = e => {
    if (fields.email !== "" || fields.password !== "") {
      var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/login_user/`;
      let formData = new FormData(loginFormRef.current);

      getCsrfToken().then(res => {
        makeRequest(url, formData, "post", "", res.data).then(response => {
          if (response.status == 200) {
            window.sessionStorage.setItem("log", response.username);
            setTimeout(() => {
              navigate("/budgets/orcamentos");
            }, 500);
          }
          if (response.status == 401) {
            alert("Credencial não autorizada.");
          }
        });
      });
    } else {
      window.alert("Preencha todos os campos para logar.");
    }
  };
  const handleSSOAuth = e => {
    window.location.href = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/auth/sign_in`;
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <LoginComp ref={loginFormRef}>
          <h3>
            <span>smart</span>
            <b>budget</b>
          </h3>
          {/* <InputText name="email" label="E-mail" fullWidth onChange={handleInput} variant="filled" />
          <InputText name="password" type="password" label="Senha" fullWidth onChange={handleInput} variant="filled" />

          <Button onClick={handleLogin} fullWidth variant="contained" style={{backgroundColor: colors["green"]}}>
            Entrar
          </Button>
          <hr /> */}
          <Button onClick={handleSSOAuth} variant="contained" style={{backgroundColor: colors["blue"]}}>
            ACESSO SSO FCB
          </Button>
          {/* <Link style={{ color: colors["blue"], fontSize: 14, textAlign: "center" }}>
            Estou com problemas para acessar
          </Link> */}
        </LoginComp>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
