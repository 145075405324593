import React, {useEffect, useContext, useState} from "react";
import {ContainerListaOrcamento} from "./style";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";
import TableAllOrcamentos from "../../components/TableAllOrcamentos/TableAllOrcamentos";
import {useNavigate} from "react-router-dom";
import ModalBudget from "../../components/ModalBudget/ModalBudget";
import {modal} from "../../../methods/methods";
import {WebSocketContext} from "../../service/WSContext";
import listbudgets from "../../../utils/listbudgets.json";

const ListaBudgets = () => {
  var columns = ["Nome do orçamento", "Cliente", "Agência", "Valor total"];
  const [openModalNew, setOpenModalNew] = useState(false);
  const [budgets, setBudgets] = useState([]);
  // const [budgets, setBudgets] = useState(listbudgets.data.budgets);
  const [filtered, setFiltered] = useState([]);
  const navigate = useNavigate();
  const [message, sendMessageWS] = useContext(WebSocketContext);

  var originalData = [...budgets];

  const handleSearch = e => {
    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (
        b.name.toLowerCase().includes(searchString) ||
        b.agency_name.toLowerCase().includes(searchString) ||
        b.brand_name.toLowerCase().includes(searchString)
      ) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  useEffect(() => {
    sendMessageWS({method: "list_budgets"});
  }, []);

  useEffect(() => {
    setFiltered(budgets);
  }, [budgets]);

  useEffect(() => {
    if (message && message.method == "list_budgets") {
      setBudgets(message.data.budgets);
    }

    if (message && message.method == "duplicate_budget") {
      setBudgets(message.data.budgets);
    }

    if (message && message.method == "update_budget") {
      navigate(`${message.data}`);
    }
  }, [message]);

  const handleClickCta = e => {
    modal.open(setOpenModalNew);
  };

  useEffect(() => {
    document.title = `BUDGET | Orçamentos`;
  }, []);

  return (
    <ContainerListaOrcamento>
      <HeaderGeral
        subtitulo={"Orçamentos"}
        btnCta={"Novo Orçamento"}
        placeholder={"Pesquise um orçamento..."}
        handleClickCta={handleClickCta}
        search={true}
        budget={true}
        handleSearch={handleSearch}
      />

      <TableAllOrcamentos columns={columns} rows={filtered} listdata={filtered} />

      {openModalNew && (
        <ModalBudget
          open={openModalNew}
          onClose={e => {
            modal.close(setOpenModalNew);
          }}
          modalTitle={"Novo orçamento"}
        />
      )}
    </ContainerListaOrcamento>
  );
};

export default ListaBudgets;
