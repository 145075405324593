
import styled from "styled-components";
import colors from "../../../utils/colors.json";
import {Container} from "../ListaClientes/style";

const ContainerListaCargos = styled(Container)`
background: ${colors["page-todos-recursos"]};
`;

export {ContainerListaCargos};

