import {TableContainer, Table, TableHead, TableRow, TableCell, Button, Paper, TableBody} from "@mui/material";
import {table} from "../../../methods/methods";

const DetailSummary = ({project}) => {
  const styleCell = {
    lineHeight: "1em",
    textAlign: "center",
    whiteWrap: "nowrap",
  };

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible"}}>
      <Table sx={{minWidth: 650, overflowY: "visible"}} aria-label="simple table">
        
        <TableHead
            style={{
              backgroundColor: "#f8f7f7",
            }}>
          <TableRow
            style={{
              backgroundColor: "#f8f7f7",
            }}>
            <TableCell>
              <h4>Total Projeto</h4>
            </TableCell>
            <TableCell align="right">
              <h4>{table.numberToReais(project.project_total)}</h4>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default DetailSummary;
