import React, {useEffect, useState, useContext} from "react";
import {Container} from "../DetailBudget/style";
import {TableContainer, Paper, Table, TableHead, TableRow, TextField, TableCell, TableBody} from "@mui/material";
import ClausulasHeader from "./ClausulasHeader";
import {Delete} from "@mui/icons-material";
import {WebSocketContext} from "../../service/WSContext";
import ClausulaBudgetRow from "./ClausulaBudgetRow";


const ClausulasBudget = ({budget_uuid}) => {
  const [clausulas, setClausulas] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  useEffect(() => {
    sendMessageWS({
      method: "list_clausulabudgets",
      data: {budget_uuid: budget_uuid},
    });
  }, []);

  useEffect(() => {
    if (message && message.method == "list_clausulabudgets" || message && message.method == "update_add_clausulabudget" || message && message.method == "update_clausulabudget" || message && message.method == "update_move_clausulabudget" ) {
      setClausulas(message.data.clausulas);

    }
  }, [message]);

  const styleCell = {
    lineHeight: "1em",
    textAlign: "left",
    whiteSpace: "nowrap",
    verticalAlign: "top",
  };

  return (
    <Container>
      <ClausulasHeader budget_uuid={budget_uuid} />

      <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible"}}>
        <Table sx={{minWidth: 650, overflowY: "visible"}} aria-label="simple table">
          <TableHead
            style={{
              backgroundColor: "#f8f7f7",
            }}>
            <TableRow>
              <TableCell style={styleCell}>Cláusulas</TableCell>
            </TableRow>
          </TableHead>

          <TableBody style={{position: "relative"}}>
            {clausulas.map((clausula, i) => {
              return <ClausulaBudgetRow idx={clausula.idx} key={clausula.uuid} clausula={clausula} budget_uuid={budget_uuid} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ClausulasBudget;
