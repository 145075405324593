import React, {useContext, useState, useEffect} from "react";
import {Container} from "./style";
import HeaderGeral from "../../components/HeaderGeral/HeaderGeral";
import {Tab, Tabs} from "@mui/material";
import BonusSettings from "../../components/BonusSettings/BonusSettings";
import AccessSettings from "../../components/AccessSettings/AccessSettings";
import BudgetSettings from "../../components/BudgetSettings/BudgetSettings";
import TabPanel from "../../components/TabPanel/TabPanel";
import {WebSocketContext} from "../../Budget/service/WSContext";
import getCookie from "../../utils/GetCookie";

const defineProps = index => {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Configuracoes = () => {
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [tabValue, setTabValue] = useState(0);
  const [financialParams, setFinancialParams] = useState([]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    document.title = `BUDGET | Configurações`;
    sendMessageWS({
      method: "list_params",
      data: {
        agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
      },
    });
  }, []);

  useEffect(() => {
    if (message && message.data) {
      setFinancialParams(message.data);
    }
  }, [message]);

  return (
    <Container>
      <HeaderGeral
        // titulo={"Geral"}
        subtitulo={"Configurações"}
        agency={false}
        year={false}
        search={false}
        budget={false}
      />

      <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab label="BUDGET" {...defineProps(0)} />
        <Tab label="BONUS" {...defineProps(1)} />
        <Tab label="ACESSOS" {...defineProps(2)} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <BudgetSettings params={financialParams} />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <BonusSettings />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <AccessSettings />
      </TabPanel>
    </Container>
  );
};

export default Configuracoes;
