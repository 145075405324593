import React, {useState} from "react";
import {ContainerHeaderGeral} from "./style";
import {FormControl, InputLabel, Select, OutlinedInput, InputAdornment, IconButton, MenuItem, Button, Box} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import ModalUpload from "../ModalUpload/ModalUpload";
import {modal} from "../../methods/methods"

const HeaderGeral = ({
  titulo,
  subtitulo,
  handleClickCta,
  planilhaCta,
  agency = false,
  year = false,
  search = false,
  budget = false,
  handleSendFile,
  btnCta,
  file,
  setFile,
  placeholder,
  handleSearch,
  openModalUpload,
  setOpenModalUpload,
  openModal,
  setOpenModal  
}) => {
  const [progress, setProgress] = useState(0);
  //const [ openModal, setOpenModal] = useState(false);
  const [agencia, setAgencia] = useState("");
  const [ano, setAno] = useState("");

  const handleFile = e => {
    setFile(e.target.files[0]);
  };

  return (
    <ContainerHeaderGeral>
      <div className="header-titulo">
        <h4>{titulo}</h4>
        <h1>{subtitulo}</h1>
      </div>

      {search && (
        <FormControl className="form-mobile">
          {budget && (
            <Box textAlign={"right"} pb={2} sx={{display: "flex", gap: 1, justifyContent: "end"}}>
              <Button
                sx={{width: window.innerWidth < 800 ? "100%" : "fit-content"}}
                startIcon={<AddIcon />}
                variant="contained"
                onClick={()=>{setOpenModal(true)}}>
                {btnCta}
              </Button>
              {planilhaCta && (
                <Button className="desktop" onClick={()=>{setOpenModalUpload(true)}} variant="contained">
                  Enviar planilha
                </Button>
              )}
              {planilhaCta && (
                <Button className="desktop" startIcon={<DownloadIcon fontSize="small" />}>
                  planilha modelo
                </Button>
              )}
            </Box>
          )}

          <div className="upload-sheets">
            {planilhaCta && (
              <Button onClick={()=>{modal.open(setOpenModal)}} size="small" variant="contained">
                Enviar planilha
              </Button>
            )}
            {planilhaCta && (
              <Button size="small">
                <DownloadIcon fontSize="small" /> planilha modelo
              </Button>
            )}
          </div>

          <OutlinedInput
            onChange={handleSearch}
            size="small"
            id="outlined-adornment-amount"
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start">
                <IconButton size="sm" aria-label="toggle password visibility">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}

      {agency && (
        <FormControl variant="standard" sx={{minWidth: 200}} size="small" className="form-mobile">
          <InputLabel id="demo-simple-select-standard-label">Agência</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={agencia}
            label="Agência"
            onChange={e => {
              setAgencia(e.target.value);
            }}>
            <MenuItem value={10}>FCB Brasil</MenuItem>
            <MenuItem value={20}>FCB SIX</MenuItem>
            <MenuItem value={30}>FCB Health</MenuItem>
          </Select>
        </FormControl>
      )}
      {year && (
        <FormControl className="form-mobile" variant="standard" sx={{minWidth: 120}} size="small">
          <InputLabel id="demo-simple-select-standard-label">Ano</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={ano}
            label="Ano"
            onChange={e => {
              setAno(e.target.value);
            }}>
            <MenuItem value={10}>2022</MenuItem>
            <MenuItem value={20}>2021</MenuItem>
            <MenuItem value={30}>2020</MenuItem>
          </Select>
        </FormControl>
      )}

      {planilhaCta && (
        <ModalUpload
          setFile={setFile}
          file={file}
          progress={progress}
          openModalUpload={openModalUpload}
          onClose={() => {
            // modal.close(setOpenModal);
            setOpenModalUpload(false);
          }}
          handleUpload={handleSendFile}
          handleFile={handleFile}
          modalTitle={"Upload de Planilha "}
          applyAll={true}
        />
      )}
    </ContainerHeaderGeral>
  );
};

export default HeaderGeral;
