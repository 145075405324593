import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "./index.css";
import Login from "./Geral/Login/Login";
import Home from "./Geral/Home/Home";
import VisaoGeralFinanceiro from "./Bonus/pages/VisaoGeral/VisaoGeralFinanceiro";
import VisaoGeralMidia from "./Bonus/pages/VisaoGeral/VisaoGeralMidia";
import VisaoVeiculoMidia from "./Bonus/pages/VisaoVeiculo/VisaoVeiculoMidia";
import ListaBudgets from "./Budget/pages/ListaBudgets/ListaBudgets";
import ListaSuppliers from "./Budget/pages/ListaSuppliers/ListaSuppliers";
import ListaServicos from "./Budget/pages/ListaServicos/ListaServicos";
import ListaCargos from "./Budget/pages/ListaCargos/ListaCargos";
import ListaClientes from "./Budget/pages/ListaClientes/ListaClientes";
import BudgetPage from "./Budget/pages/BudgetPage/BudgetPage";
import Configuracoes from "./Geral/Configuracoes/Configuracoes";
import ClientePage from "./Budget/pages/ClientePage/ClientePage";
import NovoCliente from "./Budget/pages/NovoCliente/NovoCliente";
import UnauthorizedPage from "./Geral/UnauthorizedPage/UnauthorizedPage";
import {WebSocketProvider} from "./Budget/service/WSContext";
import ListaClausulas from "./Budget/pages/ListaClausulas/ListaClausulas"



const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {path: "/nao-autorizado", element: <UnauthorizedPage />},
  {
    path: "/",
    element: (
      <WebSocketProvider>
        <Home />
      </WebSocketProvider>
    ),
    children: [
      {path: "midia", element: <VisaoGeralMidia />},
      {path: "/midia/:veiculo", element: <VisaoVeiculoMidia />},
      {path: "financeiro", element: <VisaoGeralFinanceiro />},
      {path: "budgets", element: <ListaBudgets />},
      {path: "/budgets/orcamentos", element: <ListaBudgets />},
      {path: "/budgets/fornecedores", element: <ListaSuppliers />},
      {path: "/budgets/cargos", element: <ListaCargos />},
      // {path: "/budgets/relatorios", element: <ListaRelatorios />},
      {path: "/budgets/clientes", element: <ListaClientes />},
      {path: "/budgets/servicos", element: <ListaServicos />},
      {path: "/budgets/clausulas", element: <ListaClausulas />},
      // {path: "/budgets/clientes/:cod", element: <ClientePage />},
      {path: "/budgets/orcamentos/:id", element: <BudgetPage />},
      {path: "configuracoes", element: <Configuracoes />},
    ],
  },
]);


ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router} />);
