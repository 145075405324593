import {useContext, useState, useRef} from "react";
import { InputAdornment, TableRow, TableCell, TextField, Checkbox, TableBody} from "@mui/material";
import CelulaRecursos from "../CelulaRecursos/CelulaRecursos";
import {WebSocketContext} from "../../service/WSContext";
import {table} from "../../../methods/methods";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import SupplierCarta from "../SupplierCarta/SupplierCarta";
import CustomSupplierInput from "../CustomSupplierInput/CustomSupplierInput";

const SupplierRow = props => {
  const {budget_uuid, suppliers, supplier, setSuppliers, currentProject, ...other} = props;
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleRemove = () => {
    sendMessageWS({
      method: "update_resource",
      data: {
        project_uuid: currentProject.project_uuid,
        resource_uuid: supplier.resource_uuid,
        delete_resource: true,
        budget_uuid: budget_uuid,
      },
    });
  };

  return (
    <TableRow key={supplier.resource_uuid} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
      <CelulaRecursos
        handleRemove={e => {
          setOpenConfirmDelete(true);
        }}
        role={supplier.resource_supplier}
        id={supplier.resource_uuid}
        ftes={suppliers}
        setFTEs={setSuppliers}
      />
      <TableCell align="center" sx={{minWidth: "150px", maxWidth: "200px"}}>
        <CustomSupplierInput
          type="text"
          supplier={supplier}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="resource_name"
          placeholder="Ex:. Filme, Foto"
          defaultvalue={supplier.resource_name == null ? "" : supplier.resource_name}
        />
      </TableCell>
      <TableCell align="center">
        <SupplierCarta resource_uuid={supplier.resource_uuid} supplier={supplier} />
      </TableCell>
      <TableCell align="center">
        <CustomSupplierInput
          type={"checkbox"}
          supplier={supplier}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="fwd"
          defaultvalue={supplier.resource_fwd == true ? true : false}
        />
      </TableCell>
      <TableCell align="center" sx={{minWidth: "150px"}}>
        <CustomSupplierInput
          type="number"
          supplier={supplier}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="supplier_cost"
          defaultvalue={Number(supplier.resource_cost)}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$&ensp;</InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell align="center">
        <CustomSupplierInput
          sx={{width: "fit-content", maxWidth: "100px", minWidth: "40px"}}
          type="number"
          supplier={supplier}
          project={currentProject}
          budget_uuid={budget_uuid}
          name="supplier_markup"
          defaultvalue={supplier.resource_supplier_markup === null ? "0" : Number(supplier.resource_supplier_markup)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell align="center" name="total">
        <p style={{whiteSpace: "nowrap"}}>{table.numberToReais(Number(supplier.resource_total))}</p>
      </TableCell>
      <DialogConfirmDelete open={openConfirmDelete} setDialogDeleteOpen={setOpenConfirmDelete} handleDelete={handleRemove} subject="esse recurso" />
    </TableRow>
  );
};

export default SupplierRow;
