import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Fab, Paper } from "@mui/material";
import { ContratosComp } from "./style.jsx";
import AddIcon from "@mui/icons-material/Add";

export default function Contratos({ columns, rows }) {
  useEffect(() => {}, []);

  return (
    <ContratosComp>
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          p: 2,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <div className="contratos-titulo">
          <h2>Contratos</h2>
          <Fab color="primary" size="small" aria-label="add">
            <AddIcon />
          </Fab>
        </div>
        <div className="lista-contratos">
          <div className="lista-contratos-item">A</div>
          <div className="lista-contratos-item">A</div>
          <div className="lista-contratos-item">A</div>
        </div>
      </Paper>
    </ContratosComp>
  );
}
