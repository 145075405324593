import setCookie from "./setCookie";
import axios from "axios";

const getCsrfToken = () => {
  var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/csrf/`;

  // return axios.get(url, {
  //     withCredentials: true,
  // }).then(data => {
  //     setCookie('csrftoken', data.data, 1);
  // })
  return axios({
    method: "get",
    url: url,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default getCsrfToken;
