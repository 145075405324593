import {useEffect, useState, useContext, useRef} from "react";
import {Modal, TextField, InputAdornment, Button, FormControlLabel, Checkbox} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "../ModalCreateSupplier/style";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";
import SelectInput from "../SelectInput/SelectInput";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import getCookie from "../../../utils/GetCookie";

const ModalCreateRole = ({open, onClose, current, remove, modalTitle}) => {
  const [areas, setAreas] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [agencyUUID, setAgencyUUID] = useState(getCookie("agencyuuid") ? getCookie("agencyuuid") : "");
  const [agencies, setAgencies] = useState([]);
  // const [fields, setFields] = useState({
  //   role_name: "",
  //   role_salary: "",
  //   role_kind: "",
  //   role_tbh: false,
  //   area_uuid: "",
  // });

  const roleData = useRef({
    role_name: "",
    role_salary: "",
    role_kind: "",
    role_tbh: false,
    agency_uuid: agencyUUID,
    area_uuid: "",
    role_uuid: 0,
  });

  var tipo = [
    {name: "CLT", uuid: 1},
    {name: "PJ", uuid: 2},
    {name: "Estágio", uuid: 3},
  ];

  useEffect(() => {
    if (open) {
      sendMessageWS({method: "list_areas"});
      sendMessageWS({method: "list_agencies"});
    }
  }, [open]);

  useEffect(() => {
    if (message && message.method == "list_areas" && message.data !== null) {
      setAreas(message.data.areas);
    }
    if (message && message.method == "update_role" && message.data !== null) {
      onClose();
    }
    if (message && message.method == "list_agencies") {
      setAgencies(message.data.agencies);
    }
  }, [message]);

  const handleInput = e => {
    roleData.current[e.target.name] = e.target.value;
    if (e.target.name == "agency_uuid") {
      roleData.current[e.target.name] = e.target.value;
      setAgencyUUID(e.target.value);
    }
    if (e.target.type == "number") {
      roleData.current[e.target.name] = Number(e.target.value);
    }
    if (e.target.type == "checkbox") {
      roleData.current[e.target.name] = e.target.checked;
    }
  };

  const handleUpdateRole = (remove = false) => {
    if (current) {
      sendMessageWS({
        method: "update_role",
 //       data: {...roleData.current, delete_role: remove && true, role_uuid: current.uuid, area_uuid: current.area__uuid},
          data: {...roleData.current, delete_role: remove && true, role_uuid: current.uuid}
      });
    } else {
      sendMessageWS({method: "update_role", data: roleData.current});
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          name="role_name"
          placeholder="Ex:. Redator(a) Jr., Diretor(a)"
          variant="outlined"
          label="Cargo"
          defaultValue={current && current.name}
          onChange={handleInput}
        />

        <ContainerButtons>
          <SelectInput
            name="role_kind"
            onChange={handleInput}
            sx={{flexGrow: 1}}
            variant="outlined"
            label="Tipo de contratação"
            options={tipo}
            defaultValue={current && current.kind}
          />
        </ContainerButtons>
        <SelectInput
          name="agency_uuid"
          onChange={handleInput}
          id="outlined-basic"
          sx={{flexGrow: 1}}
          variant="outlined"
          label="Agência"
          value={agencyUUID}
          options={agencies}
          inputProps={{readOnly: true}}
        />
        <SelectInput
          name="area_uuid"
          onChange={handleInput}
          id="outlined-basic"
          sx={{flexGrow: 1}}
          variant="outlined"
          label="Área"
          options={areas}
          defaultValue={current && current.area__uuid}
        />
        <ContainerButtons>
          <TextField
            type="number"
            name="role_salary"
            placeholder="2.500"
            variant="outlined"
            sx={{flexGrow: 1}}
            label="Salário"
            defaultValue={current && Number(current.salary).toFixed(2)}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$&ensp;</InputAdornment>,
            }}
            onChange={handleInput}
          />
          {/* <FormControlLabel
            control={<Checkbox name="role_tbh" defaultChecked={current && current.role_tbh} onChange={handleInput} />}
            name="role_tbh"
            label="TBH"
          /> */}
        </ContainerButtons>

        <ContainerButtons>
          {current && (
            <Button
              onClick={() => {
                setOpenDialog(true);
              }}
              variant="contained"
              color="error">
              <DeleteOutlineIcon />
            </Button>
          )}
          <Button
            className="main-btn"
            onClick={() => {
              handleUpdateRole();
            }}
            variant="contained">
            {current ? "Salvar" : "Criar"}
          </Button>
        </ContainerButtons>

        <DialogConfirmDelete
          subject={"esse recurso"}
          open={openDialog}
          handleDelete={() => handleUpdateRole("delete")}
          setDialogDeleteOpen={setOpenDialog}
        />
      </BoxContainer>
    </Modal>
  );
};

export default ModalCreateRole;
