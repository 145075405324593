import styled from "styled-components";
import colors from "../../utils/colors.json"

const Container = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  box-sizing: border-box;
  /* background: radial-gradient(
        53.91% 79.1% at 50% 55.08%,
        rgba(255, 220, 64, 0.2) 21.76%,
        rgba(255, 255, 255, 0.2) 100%
      ), */
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    /* linear-gradient(0deg, #ffffff, #ffffff); */
    background-color: ${colors["page-config"]};
  height: 100%;


  @media (max-width: 800px) {
    padding: 20px;
  }

  @media (min-width: 1500px) and (max-width: 3000px) {
    padding: 30px 15%;
  }
`;

export {Container};
