import {Modal, TextField, Slider,InputAdornment, Button} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "./style";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState, useContext, useRef} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";

const ModalCenario = ({
  open,
  onClose,
  budget_uuid,
  modalTitle,
  project_uuid,
}) => {
  const [nameFieldError, setNameFieldError] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const currentScenery = useRef({});

  const [fields, setFields] = useState({project_oh: 35, project_margin: 25, receita: ""});

  const sceneryButton = [
    {label: "OH 35% Margem 25%", fields: {project_oh: 35, project_margin: 25} , value: {project_oh: 35, project_margin: 25}},
    {label: "OH 90% Margem 50%", fields: {project_oh: 90, project_margin: 50} , value: {project_oh: 90, project_margin: 50}},
    {label: "OH 120% Margem 75%", fields: {project_oh: 120, project_margin: 75} , value: {project_oh: 120, project_margin: 75}},
  ];

  const handleInput = e => {
    currentScenery.current[e.target.name] = Number(e.target.value);

    if (e.target.name == "project_oh" ) {
      // currentScenery.current[e.target.name] = Number(e.target.value) / 100;
      setFields(fields => ({...fields, project_oh: Number(e.target.value)}));
    } 
    if (e.target.name == "project_margin") {
      // currentScenery.current["margin"] = Number(e.target.value) / 100;
      currentScenery.current["receita"] = undefined;
      setFields(fields => ({...fields, project_margin: Number(e.target.value)}));
    } 
    // if(e.target.name == "receita") {
    //   currentScenery.current["receita"] = Number(e.target.value);
    //   currentScenery.current["margin"] = undefined;
    //   setFields(fields => ({...fields, receita: Number(e.target.value), margin: ''}));
    // }
  };

  const handleSaveScenery = () => {
    sendMessageWS({
      method: "update_project",
      data: 
       {...currentScenery.current,  
        budget_uuid: budget_uuid,
        project_uuid: project_uuid
      }
    });
    onClose();
  };

  useEffect(() => {
    // console.log(fields);
  }, [fields]);

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <ContainerButtons list="true" >
          {sceneryButton.map((button,i) => {
            return (
              <Button
                key={i}
                onClick={e => {setFields(button.fields); currentScenery.current = button.value}}
                variant="contained">
                {button.label}
              </Button>
            );
          })}
        </ContainerButtons>
        <p>Cenário customizado:</p>
        <TextField
          id="outlined-basic"
          type="number"
          label="OH"
          name="project_oh"
          variant="outlined"
          onChange={handleInput}
          value={fields.project_oh}
          error={nameFieldError}
          InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
        />
        <TextField
          id="outlined-basic"
          type="number"
          label="Margem"
          name="project_margin"
          variant="outlined"
          onChange={handleInput}
          value={fields.project_margin}
          error={nameFieldError}
          InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
        />
        {/* <TextField
          id="outlined-basic"
          type="number"
          label="Receita"
          name="project_receita"
          variant="outlined"
          onChange={handleInput}
          value={fields.project_receita}
          error={nameFieldError}
          InputProps={{startAdornment: <InputAdornment position="end">R$&ensp;</InputAdornment>}}
        /> */}
        <ContainerButtons>
          <Button className="main-btn" onClick={handleSaveScenery} variant="contained">
            Salvar
          </Button>
        </ContainerButtons>
      </BoxContainer>
    </Modal>
  );
};

export default ModalCenario;
