import React, {useEffect, useContext, useState} from "react";
import {ContainerListaSuppliers} from "./style";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";
import TableAllSuppliers from "../../components/TableAllSuppliers/TableAllSuppliers";
import {useNavigate} from "react-router-dom";
import ModalCreateSupplier from "../../components/ModalCreateSupplier/ModalCreateSupplier";
import {modal} from "../../../methods/methods";
import {WebSocketContext} from "../../service/WSContext";
import useAgencyStore from "../../service/AgencyStore";
import getCookie from "../../../utils/GetCookie";

const ListaSuppliers = () => {
  var columns = ["Nome do fornecedor", ""];
  const [openModalNew, setOpenModalNew] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const navigate = useNavigate();
  const [message, sendMessageWS] = useContext(WebSocketContext);

  useEffect(() => {
    document.title = `BUDGET | Orçamentos`;
    sendMessageWS({method: "list_suppliers", data: {agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid")}});
  }, []);

  useEffect(() => {
    setFiltered(suppliers);
  }, [suppliers]);

  useEffect(() => {
    if (message && message.method == "list_suppliers") {
      setSuppliers(message.data.suppliers);
    }

    if (message && message.method == "update_supplier") {
      setSuppliers(message.data.suppliers);
    }
  }, [message]);

  const handleClickCta = e => {
    modal.open(setOpenModalNew);
  };

  var originalData = [...suppliers];

  const handleSearch = e => {
    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.name.toLowerCase().includes(searchString)) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  return (
    <ContainerListaSuppliers>
      <HeaderGeral
        subtitulo={"Fornecedores"}
        btnCta={"Novo Fornecedor"}
        placeholder={"Pesquise um fornecedor..."}
        handleClickCta={handleClickCta}
        search={true}
        budget={true}
        handleSearch={handleSearch}
      />

      <TableAllSuppliers columns={columns} rows={filtered} listdata={filtered} />

      <ModalCreateSupplier
        open={openModalNew}
        onClose={e => {
          modal.close(setOpenModalNew);
        }}
        modalTitle={"Novo fornecedor"}
      />
    </ContainerListaSuppliers>
  );
};

export default ListaSuppliers;
