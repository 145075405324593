import {Table, TableHead, TableContainer, Paper, TableRow, TableCell, TableBody} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {modal} from "../../../methods/methods"
import ModalCreateSupplier from "../ModalCreateSupplier/ModalCreateSupplier";

const TableAllSuppliers = ({columns, rows, listdata, tableclick = true}) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState();

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      variant="outlined"
      sx={{bgcolor: "transparent", marginBottom: 1, boxSizing: "border-box"}}
    >
      <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow >
            {columns.map((column, i) => {
              return (
                <TableCell style={{flexGrow: 1}} key={i}>
                  {column === " " ? <span>&nbsp;</span> : column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody style={{position: "relative"}}>
          {rows.map((row, i) => (
            <TableRow
              onClick={() => {
                modal.open(setOpenModal);
                setCurrentSupplier(row);
              }}
              hover
              key={i}
              id={row.id}
              sx={{
                "&:last-child td, &:last-child th": {border: 0},
                "cursor": tableclick && "pointer",
                "bgcolor": "#ffffffbc",
              }}
            >
              <TableCell id={row.id}>{row.name}</TableCell>
              <TableCell><EditIcon fontSize="small" /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ModalCreateSupplier
        open={openModal}
        onClose={e => {
          modal.close(setOpenModal);
        }}
        current={currentSupplier}
        modalTitle={"Editar fornecedor"}
      />

    </TableContainer>
  );
};

export default TableAllSuppliers;
