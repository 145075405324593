import styled from "styled-components";

const TitleModal = styled.h2`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  width: 100%;
`;

const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  gap: 10px;
  
  .main-btn{
    width: 100%;
  }
`;

export {TitleModal, ContainerButtons};
