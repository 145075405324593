import {useEffect, useContext, useRef, useState} from "react";
import {Box, TextField, FormControlLabel, Checkbox, Button, ListSubheader, List, ListItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {BoxContainer, TitleModal} from "../ModalProjeto/style";
import {ModalContainer} from "./style";
import {SearchRounded, Delete} from "@mui/icons-material";
import {WebSocketContext} from "../../service/WSContext";
import SelectInput from "../SelectInput/SelectInput";
import getCookie from "../../../utils/GetCookie";

const ModalBudget = ({budget, open, onClose, setDialogDeleteOpen, modalTitle, edit, copy}) => {
  const [clients, setClients] = useState();
  const [agencies, setAgencies] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [selectedBrandUUID, setSelectedBrandUUID] = useState();
  const [openClientModal, setOpenClientModal] = useState(edit ? false : true);
  const [fields, setFields] = useState({
    budget_name: edit ? budget.budget_name : "",
    brand_name: edit ? budget.budget_brand : "",
    agency_uuid: edit ? budget.budget_agency_uuid : getCookie("agencyuuid"),
  });

  const budgetData = useRef({
    budget_status: 1,
    agency_uuid: budget ? budget.budget_agency_uuid : getCookie("agencyuuid"),
  });

  const handleInput = e => {
    budgetData.current[e.target.name] = e.target.value;
    setFields(fields => ({...fields, [e.target.name]: e.target.value}));

    if (e.target.name == "agency_uuid") {
      setClients([]);
      sendMessageWS({method: "list_brands", data: {agency_uuid: e.target.value}});
    }
  };

  const handleSelectBrand = (name, uuid) => {
    setSelectedBrandUUID(uuid);
    setFields(fields => ({...fields, brand_name: name}));
  };

  const handleCreateBudget = e => {
    if (budgetData.current.budget_name == "") {
      // setFieldError(error => ({...error, budget_name: true}));
    } else {
      if (edit) {
        sendMessageWS({method: "update_budget", data: {...budgetData.current, budget_uuid: budget.budget_uuid, brand_uuid: selectedBrandUUID}});
      } else if (copy) {
        sendMessageWS({
          method: "update_duplicate_budget",
          data: {
            ...budgetData.current,
            budget_uuid: budget.uuid,
            budget_name: fields.budget_name,
          },
        });
      } else {
        sendMessageWS({method: "update_budget", data: {...budgetData.current, brand_uuid: selectedBrandUUID}});
      }
      onClose();
    }
  };

  const filteredData =
    clients &&
    clients
      .map(client => ({
        ...client,
        brands: client.brands.filter(brand => brand.brand_name.toLowerCase().includes(fields.brand_name.toLowerCase())),
      }))
      .filter(client => client.brands.length > 0 || client.client_name.toLowerCase().includes(fields.brand_name.toLowerCase()));

  useEffect(() => {
    if (message && message.method == "list_budgets") {
      // onClose();
    }
    if (message && message.method == "list_brands") {
      setClients(message.data);
    }
    if (message && message.method == "list_agencies") {
      setAgencies(message.data.agencies);
    }
  }, [message]);

  useEffect(() => {
    if (open) {
      sendMessageWS({method: "list_brands", data: {agency_uuid: getCookie("agencyuuid")}});
      sendMessageWS({method: "list_agencies"});
    }
  }, [open]);

  useEffect(() => {
    if (copy) {
      setFields({budget_name: budget.name, brand_name: budget.brand_name});
    }
  }, []);

  return (
    <ModalContainer open={open} onClose={onClose} edit={edit}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          name="budget_name"
          label="Orçamento"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={fields.budget_name}
          placeholder="Nome do orçamento"
          onChange={handleInput}
        />

        <SelectInput
          name="agency_uuid"
          onChange={handleInput}
          id="outlined-basic"
          sx={{flexGrow: 1}}
          size="small"
          variant="outlined"
          label="Agência"
          value={fields.agency_uuid}
          options={agencies}
          // defaultValue={current && current.area__uuid}
        />

        <TextField
          name="brand_name"
          label="Cliente"
          placeholder="Pesquise um cliente"
          size="small"
          onChange={handleInput}
          value={fields.brand_name}
          InputProps={{
            startAdornment: <SearchRounded sx={{marginRight: 1}} />,
            endAdornment: edit && (
              <Button
                onClick={e => {
                  setOpenClientModal(true);
                  setFields(fields => ({...fields, brand_name: ""}));
                }}>
                TROCAR
              </Button>
            ),
          }}
        />
        {!copy && (
          <div className={`clientes-list ${openClientModal && "open-list"}`}>
            {clients &&
              filteredData.map((client, i) => {
                return (
                  <List className="clientes-group" key={i}>
                    <ListSubheader className="cliente-title"> {client.client_name}</ListSubheader>
                    <div className="clientes-items">
                      {client.brands.map((brand, i) => {
                        return (
                          <ListItem
                            className={`cliente-item ${selectedBrandUUID === brand.brand_uuid && "selected-brand"}`}
                            key={brand.brand_uuid}
                            id={brand.brand_uuid}
                            onClick={e => {
                              handleSelectBrand(brand.brand_name, brand.brand_uuid);
                            }}>
                            {brand.brand_name}
                          </ListItem>
                        );
                      })}
                    </div>
                  </List>
                );
              })}
          </div>
        )}

        <FormControlLabel
          onChange={e => {
            budgetData.current["new_business"] = e.target.checked;
          }}
          control={<Checkbox defaultChecked={edit && budget.budget_new_business} />}
          label="New Business"
        />

        <div className="edit-buttons">
          <Button variant="contained" fullWidth onClick={handleCreateBudget}>
            {edit ? "Salvar" : "Criar"}
          </Button>
          {edit && (
            <Button
              onClick={e => {
                setDialogDeleteOpen(true);
              }}
              variant="contained"
              color="error">
              <Delete />
            </Button>
          )}
        </div>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalBudget;
