import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {
  VisaoGeralContainer,
  PainelSugestao,
  PainelGeral,
  ItemPainel,
  LabelItemPainel,
  VisaoGeralVeiculo
} from "../VisaoGeral/style.jsx";
import TabelaFaixas from "../../../components/TabelaFaixas/TabelaFaixas.jsx";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral.jsx";
import Contratos from "../../../components/Contratos/Contratos.jsx";
import {table} from "../../../methods/methods.js";

const columns = ["Faixa (%)", "Faixa (R$)", "Validade", "Contrato"];

const rows = [
  table.createData("50%", 200, 299, "30.06.2024", false),
  table.createData("40%", 150, 199, "30.06.2024", false),
  table.createData("20%", 100, 149, "30.06.2024", false),
  table.createData("10%", 50, 99, "30.06.2024", true),
  table.createData("5%", 10, 49, "30.06.2024", false),
];

const VisaoVeiculoMidia = () => {
  const params = useParams();

  useEffect(() => {
    document.title = `BONUS | ` + params.veiculo.toUpperCase();
  }, []);

  return (
    <VisaoGeralVeiculo>
      <HeaderGeral titulo={"Mídia"} subtitulo={`Veículo > ${params.veiculo}`} />

      <PainelSugestao>
        <h4>Sugestão de estratégica</h4>
        <ItemPainel>
          <LabelItemPainel label={"investimento"}>
            <span className="label">Investimento</span>
          </LabelItemPainel>
          <p className="painel-value">R$ 5.986,74</p>
        </ItemPainel>
        <span className="painel-bar"></span>

        <ItemPainel>
          <LabelItemPainel label={"bvesperado"}>
            <span className="label">BV Esperado</span>
            <span className="porcentagem">+24%</span>
          </LabelItemPainel>
          <p className="painel-value">R$ 5.986,74</p>
        </ItemPainel>
      </PainelSugestao>

      <div className="veiculo-wrapper">
        <div className="veiculo-wrapper-coluna">
          <PainelGeral size={"fit"}>
            <ItemPainel>
              <LabelItemPainel label={"bonusvalue"}>
                    <span className="label">Bonus Value</span>
              </LabelItemPainel>
              <p className="painel-value">R$ 5.986,74</p>
            </ItemPainel>
          </PainelGeral>

          <PainelGeral size={"fit"}>
            <ItemPainel>
              <LabelItemPainel label={"planejado"}>
                    <span className="label">Planejado</span>
              </LabelItemPainel>
              <p className="painel-value">R$ 5.986,74</p>
            </ItemPainel>
          </PainelGeral>
        </div>

        <div className="veiculo-wrapper-coluna">
          <TabelaFaixas rows={rows} columns={columns} />
          <Contratos rows={rows} columns={columns} />
        </div>
      </div>
    </VisaoGeralVeiculo>
  );
};

export default VisaoVeiculoMidia;
