import styled from "@emotion/styled";

const ContainerHeaderGeral = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
  align-items: center;
  padding-bottom: 20px;
  box-sizing: border-box;

  .header-container,
  .header-titulo {
    margin-right: auto;
  }

  .upload-sheets {
    display: none;
  }

  .form-mobile {
    min-width: 400px;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: inline-flex;
  }

  @media (max-width: 800px) {
    position: relative;
    flex-direction: column;
    /* gap: 10px; */
    width: 100%;

    .form-mobile {
      width: 100%;
      min-width: unset;
    }

    .desktop {
      display: none;
    }

    .upload-sheets {
      display: block;
      align-self: end;
      margin-bottom: 20px;
    }
  }
`;

export {ContainerHeaderGeral};
