import styled from "styled-components";
import colors from "../../../utils/colors.json";

const StatusAprovacao = styled.div`
  border: 1px solid ${colors["outline-border"]};
  border-radius: 5px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;
  color: #2e2e2eab;
  width: fit-content;
  margin: 5px 0;
  margin-right: auto;
  cursor:pointer;


${props =>
  props.status == "6" &&
  `
  background: ${colors.CANCELADO};
`}
${props =>
  props.status == "5" &&
  `
  background: ${colors.APROVADO};
`}
${props =>
  props.status == "4" &&
  `
  background: ${colors.CLIENTE};
`}
${props =>
    props.status == "3" &&
    `
    background: ${colors.FINANCEIRO};
`}
${props =>
    props.status == "2" &&
    `
    background: ${colors.OPERACOES};
`}
${props =>
    props.status == "1" &&
    `
    background: ${colors.ABERTO};
`}
`;

export {StatusAprovacao};
