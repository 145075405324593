
import styled from "styled-components";
import colors from "../../../utils/colors.json";
import {Container} from "../ListaClientes/style";

const ContainerListaServicos = styled(Container)`
background: ${colors["page-todos-servicos"]};
`;

export {ContainerListaServicos};

