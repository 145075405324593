import React, {useRef, useState, useContext, useEffect} from "react";
import {Checkbox} from "@mui/material";
import {WebSocketContext} from "../../service/WSContext";
import CustomTextField from "./style";
import {methods} from "../../../methods/methods";

const CustomRoleInput = ({id, role, project, budget_uuid, name, defaultvalue, lasteditedname, setlasteditedname, ...props}) => {
  const [saveStatus, setSaveStatus] = useState("unset");
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [lastEdited, setLastEdited] = useState(false);
  const {current} = useRef({timer: null});
  const [field, setField] = useState({
    [name]: methods.keepDecimalNotZero(defaultvalue),
  });
  const InputData = useRef();

  useEffect(() => {
    if (message && message.method == "get_budget" && props.type !== "checkbox") {
      if (lastEdited) {
        setSaveStatus("saved");
        setLastEdited(false);
        setTimeout(() => {
          setSaveStatus("unset");
        }, 1000);
      }
    }

    if (lasteditedname == "fte" && name == "hrs") {
      setField({[name]: methods.keepDecimalNotZero(defaultvalue)});
    }

    if (lasteditedname == "hrs" && name == "fte") {
      setField({[name]: methods.keepDecimalNotZero(defaultvalue)});
    }
  }, [message, project, role]);

  const autoSave = () => {
    if (current.timer) {
      clearTimeout(current.timer);
    }

    current.timer = setTimeout(() => {
      current.timer = null;
      sendMessageWS({
        method: "update_resource",
        data: {
          ...InputData.current,
          role_uuid: role.resource_role_uuid,
          area_uuid: role.resource_area_uuid,
          project_uuid: project.project_uuid,
          budget_uuid: budget_uuid,
          resource_uuid: role.resource_uuid,
          kind: 1, //Level
        },
      });
    }, 1500);
  };

  const handleInput = e => {
    setLastEdited(true);
    setlasteditedname(name);
    // InputData.current = {[e.target.name]: e.target.value};

    if (e.target.type == "number") {
      InputData.current = {[e.target.name]: Number(e.target.value)};
    }

    if (e.target.type == "text") {
      if (e.target.name == "months" || e.target.name == "hrs") {
        InputData.current = {[e.target.name]: Number(e.target.value)};
        setField(field => ({[e.target.name]: e.target.value}));
      }

      if (e.target.name == "fte") {
        var fteWithPoint = e.target.value.replace(",", ".");
        InputData.current = {[e.target.name]: Number(fteWithPoint) / 100};
        setField(field => ({[e.target.name]: e.target.value}));
      }
    }
    if (e.target.type == "checkbox") {
      InputData.current = {[e.target.name]: e.target.checked};
      setField(field => ({[e.target.name]: e.target.checked}));
    }

    autoSave();
    setSaveStatus("saving");
  };

  return (
    <>
      {props.type == "checkbox" ? (
        <Checkbox size="small" onChange={handleInput} type="checkbox" name="role_tbh" defaultChecked={role.tbh} sx={{minWidth: "fit-content"}} />
      ) : (
        <CustomTextField onChange={handleInput} size="small" value={field[name] ? field[name] : ""} name={name} className={saveStatus} {...props} />
      )}
    </>
  );
};
export default CustomRoleInput;
