import React, {useState, useRef} from "react";
import {Container, LoginComp} from "./style";
import Button from "@mui/material/Button";
import colors from "../../utils/colors.json";
import InputText from "../../components/InputText/InputText";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useNavigate} from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <div className="info-container">
          <WarningAmberRoundedIcon sx={{margin: "auto 0"}} fontSize="large" />
          <h2>Erro 401</h2>
          <p>
            Você não tem acesso ao SmartBudget. <br /> Procure o time de financeiro e solicite seu acesso.
          </p>
          <Button
            sx={{marginTop: "auto"}}
            onClick={() => {
              navigate("/login");
            }}
            variant="outlined">
            voltar para o login
          </Button>
        </div>
        <h3 className="logo-smart">
          <span>smart</span>
          <b>budget</b>
        </h3>
      </Container>
    </ThemeProvider>
  );
};

export default UnauthorizedPage;
