import {styled} from "styled-components";

const Container = styled.div`
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #ffffff9a;
  border-radius: 5px;

  .container-parametros{
    display: flex;
    gap: 20px;
    padding: 10px 0;
    box-sizing: border-box;
    flex-wrap: wrap;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }
 
`;

export {Container};
