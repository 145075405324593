import React, {useEffect, useState, useContext, useRef} from "react";
import {Container} from "../BudgetPage/style";
import {useNavigate, useParams} from "react-router-dom";
import InfosOrcamento from "../../components/InfosOrcamento/InfosOrcamento";
import CardsProjetos from "../../components/CardsProjetos/CardsProjetos";
import {CircularProgress, Tabs, Box, Tab} from "@mui/material";
import ModalProjeto from "../../components/ModalProjeto/ModalProjeto";
import {WebSocketContext} from "../../service/WSContext";
import {modal} from "../../../methods/methods";
import CardProjeto from "../../components/CardProjeto/CardProjeto";
import DetailBudget from "../../components/DetailBudget/DetailBudget";
import TabPanel from "../../../components/TabPanel/TabPanel";
import ClausulasBudget from "../../components/ClausulasBudget/ClausulasBudget";

const BudgetPage = () => {
  const {id, projectid} = useParams();
  const [budget, setBudget] = useState();
  const [allProjects, setAllProjects] = useState();
  const [openNewModal, setOpenNewModal] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [currentProject, setCurrentProject] = useState();
  const [openCardProject, setOpenCardProject] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    sendMessageWS({method: "get_budget", data: {budget_uuid: id}});
  }, []);

  const handleChangeTabAndOpenProject = uuid => {
    setTabValue(0);
    setCurrentProject(uuid);
    setOpenCardProject(true);
  };

  useEffect(() => {
    if (message !== null && message.data == null) {
      navigate("/budgets/orcamentos");
    }

    if (message && message.method == "get_budget") {
      setBudget(message.data);
    }

    if (message && message.method == "update_resource") {
      if (message.data.status === 200) {
        sendMessageWS({method: "get_budget", data: {budget_uuid: id}});
      }
    }

    if (message && message.method == "update_project") {
      if (message.data.status === 200) {
        sendMessageWS({method: "get_budget", data: {budget_uuid: id}});
      }
      modal.close(setOpenNewModal);
    }
    if (message && message.method == "update_target") {
      setBudget(message.data);
    }

    if (message && message.method == "update_budget") {
      if (message.data.status === 200) {
        sendMessageWS({method: "get_budget", data: {budget_uuid: id}});
      }
    }
    if (message && message.method == "update_add_access") {
      if (message.data.status === 200) {
        sendMessageWS({method: "get_budget", data: {budget_uuid: id}});
      }
    }
  }, [message]);

  useEffect(() => {
    if (budget) {
      setAllProjects(budget.budget_projects);
    }
  }, [budget]);

  return (
    <>
      {!budget && (
        <Container>
          <CircularProgress sx={{margin: "auto"}} size={"4em"} />
        </Container>
      )}
      {budget && (
        <Container>
          <InfosOrcamento setopencardproject={setOpenCardProject} opencardproject={openCardProject} budget={budget} closed={true} />

          <Box sx={{borderBottom: 1, marginTop: 1, borderColor: "divider"}}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab
                label="PROJETOS"
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
                value={0}
                onClick={() => {
                  setOpenCardProject(false);
                }}
              />
              <Tab
                label="CLÁUSULAS"
                id="simple-tab-1"
                aria-controls="simple-tabpanel-1"
                value={1}
              />
              {window.sessionStorage.getItem("vd-budget") === "true" && (
                <Tab label="VISÃO DETALHADA" id="simple-tab-2" aria-controls="simple-tabpanel-2" value={2} />
              )}
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            {!openCardProject && (
              <CardsProjetos
                setOpenNewModal={setOpenNewModal}
                allProjects={allProjects}
                setCurrentProject={setCurrentProject}
                setopencardproject={setOpenCardProject}
              />
            )}

            {openCardProject && (
              <CardProjeto
                budget_uuid={id}
                budget={budget}
                // setopen={setOpenProjectModal}
                projects={allProjects}
                setopencardproject={setOpenCardProject}
                project_uuid={currentProject}
              />
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
              <ClausulasBudget budget_uuid={budget.budget_uuid}  />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <DetailBudget handleChangeTabAndOpenProject={handleChangeTabAndOpenProject} projects={budget.budget_projects} />
          </TabPanel>

          {openNewModal && (
            <ModalProjeto
              budget_uuid={id}
              open={openNewModal}
              onClose={() => {
                modal.close(setOpenNewModal);
              }}
              sendMessageWS={sendMessageWS}
              modalTitle="Novo Projeto"
              buttonLabel="CRIAR"
            />
          )}
        </Container>
      )}
    </>
  );
};

export default BudgetPage;
