import React, {useState, useEffect} from "react";
import {Container} from "./style";
import {Edit} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import DetailTableRole from "./DetailTableRoles";
import DetailTableSuppliers from "./DetailTableSuppliers";
import DetailSummary from "./DetailSumary";

const DetailBudget = ({handleChangeTabAndOpenProject, projects}) => {
  return (
    <Container >
      {projects &&
        projects.map(project => {
          return (
            <div className="tables-container" key={project.project_uuid} >
              <h3 className="title-detail-project">
                Projeto: {project.project_name}{" "}
                <Edit
                  onClick={e => {
                    handleChangeTabAndOpenProject(project.project_uuid);
                  }}
                  fontSize="small"
                />
              </h3>

              <DetailSummary project={project}  />
              <DetailTableRole project={project} />
              <DetailTableSuppliers project={project} />
              <Divider />
            </div>
          );
        })}
    </Container>
  );
};

export default DetailBudget;
