import {TableContainer, Table, TableHead, TableRow, TableCell, Button, Paper, TableBody} from "@mui/material";
import RoleRow from "../RoleRow/RoleRow";

const TablePessoas = ({handleOpenModalFTE, ...props}) => {
  const styleCell = {
    lineHeight: "1em",
    textAlign: "center",
    whiteWrap: "nowrap",
  };

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible", transition: "0.3s all ease-in"}}>
      <Table sx={{minWidth: 650, overflowY: "visible"}} aria-label="simple table">
        <TableHead
          style={{
            backgroundColor: "#f8f7f7",
          }}>
          <TableRow>
            <TableCell sx={{minWidth: 150}}>
              <Button variant="contained" color="primary" onClick={handleOpenModalFTE}>
                + ADICIONAR PESSOA
              </Button>
            </TableCell>

            <TableCell style={styleCell}>Contratar</TableCell>
            <TableCell style={styleCell} sx={{minWidth: 80}}>
              Meses
            </TableCell>
            <TableCell style={styleCell} sx={{minWidth: 80}}>
              Horas <br /> /mês
            </TableCell>
            <TableCell style={styleCell}>Horas Total</TableCell>
            <TableCell style={styleCell} sx={{minWidth: 80}}>
              FTE
            </TableCell>
            <TableCell style={styleCell}>Taxa Hora</TableCell>
            <TableCell style={styleCell}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.roles.map(role => (
            <RoleRow role={role} key={role.resource_uuid} {...props} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablePessoas;
