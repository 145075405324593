import {Modal, TextField, Slider, Button} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "./style";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState, useContext, useRef} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";
import SelectInput from "../SelectInput/SelectInput";

const ModalProjeto = ({open, onClose, budget_uuid, modalTitle, buttonLabel, deleteBtn = false, handleConfirmDelete, edit = false, current = {}}) => {
  const [nameFieldError, setNameFieldError] = useState(false);
  const [areas, setAreas] = useState([]);
  const [currentProject, setCurrentProject] = useState({...current, budget_uuid: budget_uuid});
  const [message, sendMessageWS] = useContext(WebSocketContext);

  // const projectData = useRef({
  //   budget_uuid: budget_uuid
  // });

  const handleInput = e => {
    setCurrentProject(currentProject => ({...currentProject, [e.target.name]: e.target.value}));
  };

  useEffect(() => {
    if (!currentProject.project_name) {
      setNameFieldError(true);
    } else {
      setNameFieldError(false);
    }
  }, [currentProject]);

  const handleCreateProject = nameFieldError => {
    if (!nameFieldError) {
      if (edit) {
        sendMessageWS({
          method: "update_project",
          data: {
            project_uuid: currentProject.project_uuid,
            project_description: currentProject.project_description,
            project_name: currentProject.project_name,
            budget_uuid: budget_uuid,
          },
        });
      } else {
        sendMessageWS({
          method: "update_project",
          data: currentProject,
        });
      }
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>
        <TextField
          name="project_name"
          placeholder="Nome do projeto"
          variant="outlined"
          onChange={handleInput}
          value={currentProject.project_name ? currentProject.project_name : ""}
          error={nameFieldError}
          helperText={nameFieldError ? "Campo obrigatório" : ""}
        />

        <TextField
          name="project_description"
          onChange={handleInput}
          label="Descrição"
          multiline
          rows={4}
          value={currentProject.project_description ? currentProject.project_description : ""}
        />

        <ContainerButtons>
          {deleteBtn && (
            <Button
              onClick={() => {
                handleConfirmDelete();
                // setProjectName("");
                onClose();
              }}
              variant="contained"
              color="error">
              <DeleteOutlineIcon />
            </Button>
          )}
          <Button
            className="main-btn"
            onClick={() => {
              handleCreateProject(nameFieldError);
            }}
            variant="contained">
            {buttonLabel}
          </Button>
        </ContainerButtons>
      </BoxContainer>
    </Modal>
  );
};

export default ModalProjeto;
