import React, {useRef, useState, useContext, useEffect} from "react";
import {TextField, Checkbox} from "@mui/material";
import {WebSocketContext} from "../../service/WSContext";
import CustomTextField from "../CustomRoleInput/style";

const CustomSupplierInput = ({supplier, project, budget_uuid, name, defaultvalue, ...props}) => {
  const [saveStatus, setSaveStatus] = useState("unset");
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const {current} = useRef({timer: null});

  const [available, setAvailable] = useState(false);
  

  const [field, setField] = useState({
    [name]: defaultvalue,
  });


  const supplierData = useRef({
    resource_uuid: supplier.resource_uuid,
    budget_uuid: budget_uuid,
    supplier_uuid: supplier.resource_supplier_uuid,
    project_uuid: project.project_uuid,
    kind: 2, //Level
  });

  useEffect(() => {
    setField({[name]: defaultvalue});

    if (name == "supplier_markup" && supplier.resource_fwd == true){
      setAvailable('disabled');
    }else{
      setAvailable(false);
    }



    
  }, [project, supplier]);

  const autoSave = () => {
    if (current.timer) {
      clearTimeout(current.timer);
    }

    current.timer = setTimeout(() => {
      current.timer = null;

      sendMessageWS({
        method: "update_resource",
        data: supplierData.current,
      });

      setSaveStatus("saved");

      setTimeout(() => {
        setSaveStatus("unset");
      }, 1000);
    }, 1500);
  };

  const handleInput = e => {
    supplierData.current[e.target.name] = e.target.value;
    setField({[e.target.name]: e.target.value});

    if (e.target.type == "number") {
      supplierData.current[e.target.name] = Number(e.target.value);
    }

    // if (e.target.name == "supplier_markup") {
    //   var markupWithPoint = e.target.value.replace(",", ".");
    //   if (e.target.value > 0) {
    //     supplierData.current = {...supplierData.current, double_taxation: true, supplier_markup: Number(markupWithPoint)};
    //     setField(fields => ({...fields, double_taxation: true, supplier_markup: e.target.value}));
    //   } else {
    //     supplierData.current = {...supplierData.current, supplier_markup: Number(markupWithPoint)};
    //   }
    // }

    // if (e.target.name == "supplier_markup") {
    //   var markupWithPoint = e.target.value.replace(",", ".");
    //   supplierData.current = {...supplierData.current, supplier_markup: Number(markupWithPoint)};
    // }


    if (e.target.type == "checkbox") {
      supplierData.current[e.target.name] = e.target.checked;
      setField(fields => ({...fields, double_taxation: e.target.checked}));
    }

    autoSave();
    setSaveStatus("saving");
    //setAvailable('disabled');
  };

  return (
    <>
      {props.type == "checkbox" ? (
        <Checkbox
          onChange={handleInput}
          type="checkbox"
          name={name}
          checked={supplier.resource_fwd}
          sx={{minWidth: "fit-content"}}
          disabled={available}
        />
      ) : (
        <CustomTextField disabled={available} onChange={handleInput} size="small" value={field[name] ? field[name] : ""} name={name} className={saveStatus} {...props} />
      )}
    </>
  );
};
export default CustomSupplierInput;
