import React from "react";
import {TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody} from "@mui/material";
import {table} from "../../../methods/methods";

const DetailTableRole = ({project}) => {
  const styleCell = {
    lineHeight: "1em",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible"}}  >
      <Table sx={{minWidth: 650, overflowY: "visible"}} aria-label="simple table" >
        <TableHead
          style={{
            backgroundColor: "#f8f7f7",
          }}>
          <TableRow>
            <TableCell style={styleCell}>Recurso</TableCell>
            <TableCell style={styleCell}>Área</TableCell>
            <TableCell style={styleCell}>TBH</TableCell>
            <TableCell style={styleCell}>Meses</TableCell>
            <TableCell style={styleCell}>Horas /mês</TableCell>
            <TableCell style={styleCell}>Horas Total</TableCell>
            <TableCell style={styleCell}>FTE</TableCell>
            <TableCell style={styleCell}>OH</TableCell>
            <TableCell style={styleCell}>Impostos</TableCell>
            <TableCell style={styleCell}>Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody  key={project.uuid}>
          {project.project_resources.map(resource => {
            if (resource.resource_kind == 1) {
              return (
                <TableRow style={styleCell} key={resource.resource_uuid} >
                  <TableCell style={styleCell}>{resource.resource_name}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_area}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_tbh ? "Sim" : ""}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_months}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_hrs}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_HRS_total || 0}</TableCell>
                  <TableCell style={styleCell}>{(Number(resource.resource_fte) * 100).toFixed(3) + "%"}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_oh)}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_tax_value)}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_total)}</TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailTableRole;
