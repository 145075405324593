import styled from "styled-components";
import {Container} from "../BudgetPage/style";
import colors from "../../../utils/colors.json";

const ContainerNovoCliente = styled(Container)`
  background: ${colors["page-cliente"]};

  @media (min-width: 1500px) and (max-width: 3000px) {
    padding: 30px 15%;
  }
`;

export {ContainerNovoCliente};
