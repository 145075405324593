import {useEffect, useContext, useRef, useState} from "react";
import {Box, TextField, FormControlLabel, Checkbox, Button, ListSubheader, List, ListItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {BoxContainer, TitleModal} from "../ModalProjeto/style";
import {ModalContainer} from "./style";
import {SearchRounded, Delete} from "@mui/icons-material";
import {WebSocketContext} from "../../service/WSContext";
import SelectInput from "../SelectInput/SelectInput";
import getCookie from "../../../utils/GetCookie";

const ModalBudgetStatus = ({budget, open, onClose, sysaccess}) => {

  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [openClientModal, setOpenClientModal] = useState();

  const handleBudgetStatus= e => {
    var value = e.target.value;
    sendMessageWS({method: "update_budget_status", "data":{ budget_uuid:budget.budget_uuid, "status":value}})
    onClose()
  };

  var ABERTO = true;
  var OPERACOES = false;
  var FINANCEIRO = false;
  var CLIENTE = false;
  var APROVADO = false;
  var CANCELADO = true;

  //console.log("STATUS: "+budget.budget_status+" / sysaccess: "+sysaccess)

  if (budget.budget_status == 1){
    OPERACOES = true;
    ABERTO = false
  }
  if (budget.budget_status == 2 && sysaccess >= 2){
    FINANCEIRO = true;
  }
  if (budget.budget_status == 3 && sysaccess >= 3){
    CLIENTE = true;
  }
  if (budget.budget_status == 4){
    APROVADO = true;
  }



  return (
    <ModalContainer open={open} onClose={onClose} >
      <BoxContainer>
        <TitleModal>
          Mudar status: <CloseIcon onClick={onClose} />
        </TitleModal>
        <div className="edit-buttons">

          <Button value="1" fullWidth onClick={handleBudgetStatus} disabled={!ABERTO}>
            {budget.budget_status == 1 ? "> " : ""}
            ABERTO
          </Button>

          <Button value="2" fullWidth onClick={handleBudgetStatus} disabled={!OPERACOES}>
            {budget.budget_status == 2 ? "> " : ""}
            OPERAÇÕES
          </Button>

          <Button value="3" fullWidth onClick={handleBudgetStatus} disabled={!FINANCEIRO} >
            {budget.budget_status == 3 ? "> " : ""}
            FINANCEIRO
          </Button>

          <Button value="4" fullWidth onClick={handleBudgetStatus} disabled={!CLIENTE}>
            {budget.budget_status == 4 ? "> " : ""}
            CLIENTE
          </Button>

          <Button value="5" fullWidth onClick={handleBudgetStatus} disabled={!APROVADO}>
            {budget.budget_status == 5 ? "> " : ""}
            APROVADO
          </Button>

          <Button value="6" fullWidth onClick={handleBudgetStatus} disabled={!CANCELADO}>
            {budget.budget_status == 6 ? "> " : ""}
            CANCELADO
          </Button>

        </div>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalBudgetStatus;
