import React, {useState, useRef, useContext, useEffect} from "react";
import {Container} from "./style";
import {FormControl, InputAdornment, TextField} from "@mui/material";
import {WebSocketContext} from "../../Budget/service/WSContext";
import getCookie from "../../utils/GetCookie";

const BudgetSettings = ({params}) => {
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const parameters = useRef({});
  const {current} = useRef({timer: null});

  useEffect(() => {
    sendMessageWS({method: "list_params", data: {agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid")}});
  }, []);

  const handleInput = e => {
    // if (e.target.name == "MONTH") {
    // } else {
    //   parameters.current = {[e.target.name]: Number(e.target.value) / 100};
    // }
    parameters.current = {[e.target.name]: Number(e.target.value)};
    autoSave();
  };

  const autoSave = () => {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.timer = null;
      sendMessageWS({
        method: "update_params",
        data: {...parameters.current, agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid")},
        // data: {...parameters.current, agency_uuid: window.sessionStorage.getItem("ag-id") && window.sessionStorage.getItem("ag-id")},
      });
    }, 1500);
  };

  const selectAdornment = name => {
    switch (name) {
      case "MONTH":
        return "h/mês";
      default:
        return "%";
    }
  };

  return (
    <Container>
      <h3>Parâmetros Financeiros</h3>
      <p>O valor configurado abaixo será aplicado a todos os orçamentos:</p>
      <div className="container-parametros">
        {params.length > 0 &&
          params.map((p, i) => {
            return (
              <TextField
                key={i}
                sx={{minWidth: "20%"}}
                name={p.param_name}
                label={p.param_name}
                InputProps={{
                  inputProps: {min: 0, max: 100},
                  endAdornment: <InputAdornment position="end">{selectAdornment(p.param_name)}</InputAdornment>,
                }}
                type="number"
                variant="outlined"
                defaultValue={p.param_percent ? p.param_percent : p.param_value}
                onChange={handleInput}></TextField>
            );
          })}
      </div>
    </Container>
  );
};

export default BudgetSettings;
