import React from "react";
import {MenuItemComp} from "./style";
import { useNavigate } from "react-router-dom";
import {modal} from "../../methods/methods"

const MenuItem = ({type, label, path, setOpen, setModal, startIcon, ...props}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if(setModal){
            modal.open(setModal)
        }else{
            navigate(path);
        }
        setOpen(false);
    };

    return (
        <MenuItemComp {...props} className={type} onClick={handleClick}>
            {startIcon}{label}
        </MenuItemComp>
    );
};

export default MenuItem;
