import styled from "styled-components";
import {Modal} from "@mui/material";
import colors from "../../../utils/colors.json";

const ModalContainer = styled(Modal)`
  .clientes-list {
    border: none;
    border-radius: 4px;
    max-height: 0vh;
    overflow-y: scroll;
    transition: all 0.5s ease-in-out;
  }

  .open-list {
    max-height: 50vh;
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .clientes-group {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .cliente-title {
    background-color: #e7d4ff;
    width: 100%;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .clientes-items {
    padding: 10px;
  }

  .cliente-item {
    cursor: pointer;
  }

  .cargo-item:hover {
    background-color: #b67dff;
    border-radius: 50px;
    color: white;
  }

  .cliente-item {
    cursor: pointer;
    padding: 10px;
    margin: 5px 0;
  }

  .cliente-item:hover,
  .selected-brand {
    background-color: #b67dff;
    border-radius: 50px;
    font-weight: 600;
    color: white;
  }

  .edit-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-item:left;
  }

  
  @media (max-width: 800px) {
    .modal-box {
      padding: 20px;
      width: 80vw;
      height: 70vh;
    }
  }
`;

export {ModalContainer};
