import {Table, Button, Chip, TableHead, Paper, TableRow, TableCell, TableBody} from "@mui/material";
import React, {useEffect, useState, useRef, useContext} from "react";
import {modal} from "../../../methods/methods";
import {useNavigate} from "react-router-dom";
import {TableContainer} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ModalCreateClient from "../ModalCreateClient/ModalCreateClient";
import ModalSimple from "../ModalSimple/ModalSimple";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import {WebSocketContext} from "../../service/WSContext";
import getCookie from "../../../utils/GetCookie";

const TableClientes = ({columns, rows, listdata, tableclick = true}) => {
  const navigate = useNavigate();
  const [openBrandModal, setopenBrandModal] = useState(false);
  const [openClientModal, setopenClientModal] = useState(false);
  const [openEditBrandModal, setOpenEditBrandModal] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [dialogDeleteBrandOpen, setDialogDeleteBrandOpen] = useState(false);
  // const selectedClient = useRef(false);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const handleDeleteClient = () => {
    sendMessageWS({
      method: "update_client",
      data: {
        // agency_group_uuid: window.sessionStorage.getItem("ag-uu-id") && window.sessionStorage.getItem("ag-uu-id"),
        agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
        client_uuid: selectedClient.client_uuid,
        delete_client: true,
      },
    });
    setopenClientModal(false);
    setDialogDeleteOpen(false);
  };

  const handleDeleteBrand = () => {
    sendMessageWS({
      method: "update_brand",
      data: {
        // agency_group_uuid: window.sessionStorage.getItem("ag-uu-id") && window.sessionStorage.getItem("ag-uu-id"),
        agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
        brand_uuid: selectedBrand.brand_uuid,
        client_uuid: selectedBrand.client_uuid,
        delete_client: true,
      },
    });
    setOpenEditBrandModal(false);
    setDialogDeleteBrandOpen(false);
  };

  return (
    <TableContainer component={Paper} elevation={0} sx={{bgcolor: "transparent", marginBottom: 1, boxSizing: "border-box"}} variant="outlined">
      <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
        <TableHead
          style={{
            backgroundColor: "#03055916",
          }}>
          <TableRow>
            {columns.map((column, i) => {
              return (
                <TableCell sx={{minWidth: "150px"}} key={i}>
                  {column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              hover
              key={i}
              id={row.client_uuid}
              sx={{
                "&:last-child td, &:last-child th": {border: 0},
                "cursor": tableclick && "pointer",
                "bgcolor": "#ffffffa9",
              }}>
              <TableCell
                id={row.client_uuid}
                sx={{width: "20px"}}
                onClick={e => {
                  // selectedClient.current = {client_name: row.client_name, client_uuid: e.target.id};
                  setSelectedClient({client_name: row.client_name, client_uuid: row.client_uuid});
                  setopenClientModal(true);
                }}>
                <p id={row.client_uuid} style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  {row.client_name}
                  <EditIcon id={row.client_uuid} fontSize="small" />
                </p>
              </TableCell>

              <TableCell sx={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                {row.brands &&
                  row.brands.map((brand, i) => {
                    return (
                      <Chip
                        onClick={() => {
                          setSelectedBrand({brand_name: brand.brand_name, brand_uuid: brand.brand_uuid, client_uuid: row.client_uuid});
                          setOpenEditBrandModal(true);
                        }}
                        key={i}
                        label={brand.brand_name}
                        variant="outlined"
                      />
                    );
                  })}
                <Button
                  id={row.client_uuid}
                  onClick={e => {
                    // selectedClient.current = {client_name: row.client_name, client_uuid: e.target.id};
                    setSelectedClient({client_name: row.client_name, client_uuid: e.target.id});
                    setopenBrandModal(true);
                  }}>
                  + MARCA
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {openBrandModal && (
        <ModalCreateClient
          open={openBrandModal}
          onClose={e => {
            modal.close(setopenBrandModal);
          }}
          modalTitle={"Adicionar marca"}
          selectedClient={selectedClient}
        />
      )}

      {openClientModal && (
        <ModalSimple
          open={openClientModal}
          onClose={e => {
            modal.close(setopenClientModal);
          }}
          modalTitle={"Editar cliente"}
          client={selectedClient}
          brand={false}
          handleConfirmDelete={() => {
            modal.open(setDialogDeleteOpen);
          }}
        />
      )}

      {openEditBrandModal && (
        <ModalSimple
          open={openEditBrandModal}
          onClose={e => {
            modal.close(setOpenEditBrandModal);
          }}
          modalTitle={"Editar marca"}
          client={false}
          brand={selectedBrand}
          handleConfirmDelete={() => {
            modal.open(setDialogDeleteBrandOpen);
          }}
        />
      )}

      <DialogConfirmDelete
        subject={"esse cliente"}
        open={dialogDeleteOpen}
        handleDelete={handleDeleteClient}
        setDialogDeleteOpen={setDialogDeleteBrandOpen}
      />
      
      <DialogConfirmDelete
        subject={"essa marca"}
        open={dialogDeleteBrandOpen}
        handleDelete={handleDeleteBrand}
        setDialogDeleteOpen={setDialogDeleteBrandOpen}
      />
    </TableContainer>
  );
};

export default TableClientes;
