import styled from "styled-components";
import colors from "../../../utils/colors.json";

const AbaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .margin-percentage-tag {
    font-weight: 600;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid ${colors["green"]};
    color: #52b87d;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    background-color: red;
  }
`;

export {AbaContainer};
