import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { useEffect } from "react";

const DialogConfirmDelete = ({open, setDialogDeleteOpen, handleDelete, subject}) => {
  
  const handleCloseDialog = () => {
    setDialogDeleteOpen(false);
  };


  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Você tem certeza?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Apagar {subject} será irreversível e você perderá todos os dados
          contidos nele.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>VOLTAR</Button>
        <Button
          onClick={handleDelete}
          color="error"
          variant="contained"
          autoFocus
        >
          APAGAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmDelete;
