import {Table, TableHead, TableContainer, Paper, TableRow, TableCell, TableBody} from "@mui/material";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ModalAcessos from "../ModalAcessos/ModalAcessos";

const TableAcessos = ({accessList}) => {
  const navigate = useNavigate();
  const [openModalAccess, setOpenModalAccess] = useState(false);
  const selectedAccess = useRef();

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{bgcolor: "transparent", marginBottom: 1, boxSizing: "border-box"}}>
      <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
        <TableHead key="table_head_">
          <TableRow key="table_row_">
            <TableCell key="table_head_cell1">E-mail</TableCell>
            <TableCell key="table_head_cell2">Tipo de acesso</TableCell>
            <TableCell key="table_head_cell2"></TableCell>            
          </TableRow >
        </TableHead>

        <TableBody style={{position: "relative"}} align="left">
          {accessList.map(access => {
            return (
              <TableRow
                hover
                sx={{
                  "&:last-child td, &:last-child th": {border: 0},
                  "bgcolor": "#ffffffbc",
                }}
                
                onClick={() => {
                  setOpenModalAccess(true);
                  selectedAccess.current = access;
                }}
                
                >
                <TableCell style={{width: "fit-content"}}>{access.user__email}</TableCell>
                <TableCell style={{width: "fit-content"}}>{access.label}</TableCell>
                <TableCell
  
                  style={{width: "fit-content", cursor: "pointer", textAlign: "right"}}>
                  <EditIcon />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {openModalAccess && (
        <ModalAcessos
          open={openModalAccess}
          onClose={() => {
            setOpenModalAccess(false);
          }}
          modalTitle={"Editar acesso"}
          current={selectedAccess.current}
        />
      )}
    </TableContainer>
  );
};

export default TableAcessos;
