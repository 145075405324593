import {useEffect, useState, useContext} from "react";
import {TextField, List, ListItem} from "@mui/material";
import {TitleModal, BoxContainer} from "../ModalProjeto/style";
import {Close, SearchRounded} from "@mui/icons-material";
import {WebSocketContext} from "../../service/WSContext";
import getCookie from "../../../utils/GetCookie";
import {ModalContainer} from "../ModalPermissions/style";

const ModalClausulaBudget = ({open, onClose, budget_uuid, remove, modalTitle}) => {
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [clausulas, setClausulas] = useState([]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    sendMessageWS({method: "list_clausulas", data: {agency_uuid: getCookie("agencyuuid")}});
  }, []);

  useEffect(() => {
    if (message && message.method == "list_clausulas" ) {
      setClausulas(message.data.clausulas);
      setFiltered(message.data.clausulas);
    }
    if (message && message.method == "update_resource" && message.data !== null || message && message.method == "update_add_clausulabudget" ) {
      onClose();
    }
  }, [message]);

  const handleSearch = e => {
    var originalData = clausulas;

    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.name.toLowerCase().includes(searchString)) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  const handleAddClausula = clausula => {
    sendMessageWS({
      method: "update_add_clausulabudget",
      data: {agency_uuid: getCookie("agencyuuid"), clausula_uuid: clausula.uuid, budget_uuid: budget_uuid},
    });
  };

  return (
    <ModalContainer open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <Close onClick={onClose} />
        </TitleModal>

        <TextField
          size="small"
          onChange={handleSearch}
          InputProps={{
            startAdornment: <SearchRounded sx={{marginRight: 1}} />,
          }}
        />

        <List className="clausula-list">
          {filtered.map((clausula, i) => {
            return (
              <ListItem
                className="cargo-item"
                key={i}
                onClick={e => {
                  handleAddClausula(clausula);
                }}>
                {clausula.name}
              </ListItem>
            );
          })}
        </List>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalClausulaBudget;
