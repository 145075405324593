import {useEffect, useState, useContext} from "react";
import {TextField, Button, InputAdornment} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {BoxContainer} from "../ModalProjeto/style";
import {Delete} from "@mui/icons-material";
import {TitleModal} from "../ModalProjeto/style";
import {ModalContainer, VisuallyHiddenInput} from "../ModalFTE/style";
import {WebSocketContext} from "../../service/WSContext";
import getCookie from "../../../utils/GetCookie";

const ModalSimple = ({open, onClose, handleConfirmDelete, modalTitle, client, brand}) => {
  const [clientName, setClientName] = useState("");
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const handleEditClient = () => {
    if (client) {
      sendMessageWS({
        method: "update_client",
        data: {
          client_uuid: client.client_uuid,
          client_name: clientName,
          agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
        },
      });
    } else {
      sendMessageWS({
        method: "update_brand",
        data: {
          brand_uuid: brand.brand_uuid,
          brand_name: clientName,
          agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
          client_uuid: brand.client_uuid
        },
      });
    }
  };

  useEffect(() => {
    if (message && message.method == "update_client" && message.data !== null) {
      setClientName("");
      onClose();
    }
    if (message && message.method == "update_brand" && message.data !== null) {
      setClientName("");
      onClose();
    }
  }, [message]);

  useEffect(() => {
    setClientName(client.client_name || client.brand_name);
  }, [client, brand]);

  return (
    <ModalContainer open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          variant="outlined"
          label="Nome do Cliente"
          defaultValue={client.client_name || brand.brand_name}
          onChange={e => {
            setClientName(e.target.value);
          }}
        />

        <div className="buttons-container">
          <Button
            onClick={() => {
              handleConfirmDelete();
              onClose();
            }}
            variant="contained"
            color="error">
            <Delete />
          </Button>

          <Button fullWidth onClick={handleEditClient} variant="contained">
            SALVAR
          </Button>
        </div>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalSimple;
