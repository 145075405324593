import {useEffect, useState, useContext, useRef} from "react";
import {Modal, Fab, Box, TextField, Chip, Button, FormControlLabel, Checkbox} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "../ModalCreateSupplier/style";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import {Add} from "@mui/icons-material";
import getCookie from "../../../utils/GetCookie";
import SelectInput from "../SelectInput/SelectInput";

const ModalCreateClient = ({open, onClose, selectedClient, remove, modalTitle}) => {
  const [clientUUID, setClientUUID] = useState(selectedClient && selectedClient.client_uuid);
  // pegar o uuid do list_brands pra vir a agencia do cliente ao inves dos cookies
  const [agencyUUID, setAgencyUUID] = useState(getCookie("agencyuuid") ? getCookie("agencyuuid") : "");
  const [agencies, setAgencies] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [brands, setBrands] = useState([]);
  const [currentBrand, setCurrentBrand] = useState(null);

  const clientData = useRef({
    client_name: "",
    // agency_group_uuid: window.sessionStorage.getItem("ag-uu-id") && window.sessionStorage.getItem("ag-uu-id"),
    agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
  });

  const brandData = useRef({
    brand_name: "",
    // agency_group_uuid: window.sessionStorage.getItem("ag-uu-id") && window.sessionStorage.getItem("ag-uu-id"),
    agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid"),
  });

  useEffect(() => {
    if (selectedClient) {
      setClientUUID(selectedClient && selectedClient.client_uuid);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (open == false) {
      setClientUUID(false);
    }
    if (open) {
      sendMessageWS({method: "list_agencies"});
    }
  }, [open]);

  useEffect(() => {
    if (message && message.method == "update_client" && message.data !== null) {
      setClientUUID(message.data);
      onClose();
    }
    if (message && message.method == "list_agencies") {
      setAgencies(message.data.agencies);
    }
  }, [message]);

  const handleUpdateClient = () => {
    sendMessageWS({
      method: "update_client",
      data: {...clientData.current, agency_uuid: agencyUUID},
    });
  };

  const handleUpdateBrand = () => {
    sendMessageWS({
      method: "update_brand",
      data: {...brandData.current, client_uuid: selectedClient.client_uuid},
    });
  };

  const handleInput = e => {
    if (e.target.name == "agency_uuid") {
      setAgencyUUID(e.target.value);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      {clientUUID ? (
        <BoxContainer>
          <TitleModal>
            Adicionar marca {selectedClient? `em ${selectedClient.client_name}`:''} <CloseIcon onClick={onClose} />
          </TitleModal>

          <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
            <TextField
              id="input-marca"
              sx={{flexGrow: 1}}
              variant="outlined"
              label="Nome da Marca"
              defaultValue={currentBrand}
              onChange={e => {
                setCurrentBrand(e.target.value);
                brandData.current["brand_name"] = e.target.value;
              }}
            />
            <Fab
              onClick={e => {
                handleUpdateBrand();
                setBrands([...brands, currentBrand]);
                setCurrentBrand("");
                document.getElementById("input-marca").value = "";
              }}
              size="small"
              color="primary"
              aria-label="add">
              <Add />
            </Fab>
          </Box>

          {brands.length > 0 && (
            <Box sx={{display: "flex", flexWrap: "wrap", alignItems: "center", gap: "10px"}}>
              {brands.map((b, i) => {
                return <Chip key={i} label={b} variant="outlined" />;
              })}
            </Box>
          )}
        </BoxContainer>
      ) : (
        <BoxContainer>
          <TitleModal>
            {modalTitle} <CloseIcon onClick={onClose} />
          </TitleModal>

          <TextField
            placeholder=""
            variant="outlined"
            label="Nome do Cliente"
            defaultValue={selectedClient && selectedClient.client_name}
            onChange={e => {
              clientData.current["client_name"] = e.target.value;
            }}
          />
          <SelectInput
            name="agency_uuid"
            onChange={handleInput}
            id="outlined-basic"
            sx={{flexGrow: 1}}
            variant="outlined"
            label="Agência"
            value={agencyUUID}
            options={agencies}
            inputProps={{readOnly: true}}
            // defaultValue={current && current.area__uuid}
          />
          <ContainerButtons>
            <Button
              className="main-btn"
              onClick={() => {
                handleUpdateClient();
              }}
              variant="contained">
              Salvar
            </Button>
          </ContainerButtons>
        </BoxContainer>
      )}
    </Modal>
  );
};

export default ModalCreateClient;
