import {useEffect, useContext, useState, useRef} from "react";
import { TextField, List, ListSubheader, ListItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {TitleModal} from "../ModalProjeto/style";
import {ModalContainer} from "./style";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {WebSocketContext} from "../../service/WSContext";
import { BoxContainer } from "../ModalProjeto/style";

const ModalFTE = ({open, onClose, budget_uuid, modalTitle, agency_uuid, currentProject}) => {
  const [roles, setRoles] = useState(null);
  const [unfiltered, setUnfiltered] = useState(null);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const handleAddResource = (role) =>{
    sendMessageWS({
        method: "update_resource",
        data: {
          kind: 1, //Level
          fte: 0,
          hrs: 0,
          months: 0,
          role_uuid: role.uuid,
          area_uuid: role.area__uuid,
          project_uuid: currentProject.project_uuid,
          budget_uuid: budget_uuid,
          role_tbh: false,
        }
    });
    onClose()
  }

  const groupByArea = data => {
    var rolesByArea = Object.groupBy(data, ({area__name}) => area__name);
    return rolesByArea;
  };

  useEffect(() => {
    if (message && message.method == "list_roles") {
      setRoles(groupByArea(message.data.roles));
      setUnfiltered(message.data.roles);
    }
  }, [message]);

  useEffect(() => {
    sendMessageWS({method: "list_roles", data: {agency_uuid: agency_uuid}});
  }, []);

  const handleSearch = e => {
    var originalData = groupByArea(unfiltered);

    var searchString = e.target.value.toLowerCase();
    const dataFiltered = {cargos: []};

    for (const area in originalData) {
      const cargosFiltered = originalData[area].filter(cargo => cargo.name.toLowerCase().includes(searchString));
      const areaFiltered = originalData[area].filter(cargo => cargo.area__name.toLowerCase().includes(searchString));
      dataFiltered.cargos.push(...cargosFiltered);
      dataFiltered.cargos.push(...areaFiltered);
    }

    if (e.target.value.length == 0) {
      setRoles(originalData);
    } else {
      setRoles(groupByArea(dataFiltered.cargos));
    }
  };

  return (
    <ModalContainer open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          size="small"
          onChange={handleSearch}
          InputProps={{
            startAdornment: <SearchRoundedIcon sx={{marginRight: 1}} />,
          }}
        />
        <div className="cargos-list">
          {roles &&
            Object.keys(roles).map(role => {
              return (
                <List className="cargos-group" key={role}>
                  <ListSubheader className="cargo-title"> {role}</ListSubheader>
                  <div className="cargos-items">
                    {roles[role].map((r, i) => {
                      return (
                        <ListItem
                          className="cargo-item"
                          key={i}
                          onClick={e => {
                            handleAddResource(r);
                          }}>
                          {r.name}
                        </ListItem>
                      );
                    })}
                  </div>
                </List>
              );
            })}
        </div>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalFTE;
