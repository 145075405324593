import styled from "styled-components";

const FiltroContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin: 20px 0;

  .filtro-titulo,
  .filtro-campos {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    flex: 1;
  }

  .filtro-campos {
    flex: 2;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;

    .filtro-titulo {
      width: 100%;
    }

    .filtro-campos {
      position: relative;
      flex-direction: column;
      width: 100%;
      height: 0px;
      min-height: 0px;
      max-height: 0px;
      overflow-y: hidden;
      transition: all .5s ease;
    }

    .filtro-campos.open {
      /* height: 250px; */
      min-height: 100px;
      max-height: 100px;
      overflow-y: visible;
    }
  }
`;

export {FiltroContainer};
