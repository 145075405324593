import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import CellValorDinamico from "../CellValorDinamico/CellValorDinamico";
import {useEffect} from "react";

export default function TabelaFaixas({columns, rows}) {
  var investimentoTotal = 184;

  const navigate = useNavigate();
  const handleVeiculo = (e) => {
    navigate(e.target.id.toLowerCase());
  };

  const calculaPreenchimento = (min, max, investimento = investimentoTotal) => {
    var increase;
    if (investimento > min && investimento < max) {
      var resto = max - investimento;
      increase = 1 - resto / (max - min);
    }
    if (max < investimento) {
      increase = 1;
    }
    if (investimento < min) {
      increase = 0;
    }
    return increase;
  };

  useEffect(() => {}, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TableRow>
            {columns.map((column, i) => {
              return (
                <TableCell
                  sx={{minWidth: column === "Faixa (R$)" && `15vw`, whiteSpace: "nowrap"}}
                  key={i}
                >
                  {column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody style={{position: "relative"}}>
          {rows.map((row, i) => (
            <TableRow
              // onClick={{}}
              key={i}
              id={row.veiculo}
              sx={{"&:last-child td, &:last-child th": {border: 0}}}
            >
              <TableCell id={row.veiculo} component="th" scope="row">
                {row.name}
              </TableCell>

              <TableCell id={i}>
                <CellValorDinamico
                  increase={calculaPreenchimento(
                    row.valormin,
                    row.valormax,
                    investimentoTotal
                  )}
                  valormin={row.valormin}
                  valormax={row.valormax}
                />
              </TableCell>

              <TableCell id={row.veiculo}>{row.param1}</TableCell>
              <TableCell id={row.veiculo}>
                {row.param2 && <FileDownloadOutlinedIcon />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
