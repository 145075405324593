import axios from "axios";

const makeRequest = (url, data, method, onUpload, csrf) => {
  // getCsrfToken();

  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.headers.common["X-CSRFToken"] = csrf;
  axios.defaults.headers.common["X-Frame-Options"] = 'SAMEORIGIN';
  axios.defaults.withCredentials = true;
  axios.defaults.onUploadProgress = onUpload;

  return axios({
    method: method,
    url: url,
    data: data,
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export default makeRequest;
