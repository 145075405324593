import styled, {css} from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  /* gap: 20px; */
  flex-direction: column;
  padding: 30px 40px;
  box-sizing: border-box;
  background: ${colors["page-orcamento"]};
  height: 100%;

  .titulo-tables {
    margin: 0;
    background-color: ${colors["page-orcamento"]};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
    text-align: center;
    font-weight: 600;
    color: #30764d;
  }

  @media (max-width: 800px) {
    padding: 20px;
  }

  @media (min-width: 1500px) and (max-width: 3000px) {
    padding: 30px 15%;
  }

  .container-status {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .project-title {
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    /* border: 1px solid ${colors["outline-border"]}; */
  }

  .hidden {
    visibility: hidden;
  }
  .cta-aprovacao {
    /* margin-right: auto; */
  }

  .cliente-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
  .cliente-row:nth-child(1) {
    margin-bottom: 20px;
  }

  .row-edit {
    width: 100%;
  }

  .btn-container {
    margin-top: 20px;
  }

  .container-tools,
  .project-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .project-item-total {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 10px;
    color: #1976d2;
    align-items: center;
    justify-content: end;
    gap: 5px;
    h4 {
      font-size: 1.2em;
    }
  }

  .container-tools {
    margin: 15px 0;
    justify-content: end;
  }

  @media (max-width: 800px) {
    .cliente-row {
      flex-direction: column;
    }

    .project-item-total {
      display: none;
    }
  }
`;

const PainelInfos = styled.div`
  display: flex;
  justify-content: stretch;
  box-sizing: border-box;
  gap: 20px;
  width: 100%;

  /* margin: 10px 0; */

  ${props =>
    props.size === "fit" &&
    `
    width: fit-content;
  `}
`;

const ItemPainel = styled.div`
  box-sizing: border-box;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  /* margin-bottom: 20px; */
  padding: 15px 30px;
  background-color: #2b558c;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  width: fit-content !important;
  /* height: fit-content; */

  &:nth-last-child(1) {
    border: none !important;
  }

  .painel-value {
    font-size: 1.8em;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    white-space: nowrap;
    color: white;
  }

  @media (min-width: 1600px) {
    padding: 30px;
    .painel-value {
      font-size: 2em;
    }
  }

  ${props =>
    props.variant === "plain"
      ? `
      background-color: transparent;
      box-shadow: none;
      min-width: 10%;
      align-items: center;
      padding: 0px;

      .painel-value{
        color: #000;
      }

      @media (min-width: 1600px) {
    padding: 0px;}
    `
      : ``}
`;

const LabelItemPainel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    color: white;
    padding: 2px 10px;
    width: fit-content;
    border-radius: 20px;
    box-sizing: border-box;
    font-size: 14px;
    margin-left: -5px;

    ${props =>
      props.label === "faturado" &&
      css`
        background-color: ${colors["orange"]};
      `}
    ${props =>
      props.label === "provisao" &&
      css`
        background-color: ${colors["green"]};
      `}
  ${props =>
      props.label === "pvesperado" &&
      css`
        background-color: ${colors["green"]};
      `}
  ${props =>
      props.label === "bonusvalue" &&
      css`
        background-color: ${colors["blue"]};
      `}
  ${props =>
      props.label === "bvesperado" &&
      css`
        background-color: ${colors["blue"]};
      `}
  ${props =>
      props.label === "veiculado" &&
      css`
        background-color: ${colors["blue"]};
      `}
  ${props =>
      props.label === "investimento" &&
      css`
        background-color: ${colors["purple"]};
      `}
  ${props =>
      props.label === "planejado" &&
      css`
        background-color: ${colors["orange"]};
      `}
  }

  .porcentagem {
    font-weight: 600;
    font-size: 18px;

    ${props =>
      props.label === "faturado" &&
      css`
        color: ${colors["orange"]};
      `}
    ${props =>
      props.label === "provisao" &&
      css`
        color: ${colors["green"]};
      `}
  ${props =>
      props.label === "pvesperado" &&
      css`
        color: ${colors["green"]};
      `}
  ${props =>
      props.label === "bonusvalue" &&
      css`
        color: ${colors["blue"]};
      `}
  ${props =>
      props.label === "bvesperado" &&
      css`
        color: ${colors["blue"]};
      `}
  ${props =>
      props.label === "veiculado" &&
      css`
        color: ${colors["blue"]};
      `}
  ${props =>
      props.label === "investimento" &&
      css`
        color: ${colors["purple"]};
      `}
  ${props =>
      props.label === "planejado" &&
      css`
        color: ${colors["purple"]};
      `}
  }

  @media (min-width: 1600px) {
    .label {
      font-size: 18px;
    }
  }
`;

export {Container, PainelInfos, ItemPainel, LabelItemPainel};
