import styled from "styled-components";
import {Modal} from "@mui/material";
import colors from "../../../utils/colors.json";

const ModalContainer = styled(Modal)`
  .cargos-list , .supplier-list, .clausula-list{
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0;
  }

  .supplier-list{
    padding: 10px;
    box-sizing: border-box;
  }

  .cargos-group {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .cargo-title {
    background-color: #e7d4ff;
    width: 100%;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .cargos-items {
    padding: 10px;
  }

  .cargo-item {
    cursor: pointer;
  }

  .cargo-item:hover {
    background-color: #b67dff;
    border-radius: 50px;
    color: white;
  }

  .buttons-container{
    display: flex;
    align-items: center;
    gap: 10px;
  }

`;


const VisuallyHiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 10px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 10px;
`

export {ModalContainer, VisuallyHiddenInput};
