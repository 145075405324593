import styled from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  padding: 20px 0;
  background-color: ${colors.white};
  margin-top: 20px;
  border: 1px solid ${colors["outline-border"]};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;

  &,
  .tables-container,
  .title-detail-project {
    display: flex;
  }
  &,
  .tables-container {
    flex-direction: column;
    gap: 20px;
  }

  .title-detail-project {
    justify-content: space-between;
  }

  .clausula-list {
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0;
  }
`;

export {Container};
