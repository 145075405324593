import {useEffect, useState, useContext} from "react";
import {List, ListItem, TextField, Button, InputAdornment} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {BoxContainer, TitleModal} from "../ModalProjeto/style";
import {Upload, SearchRounded} from "@mui/icons-material";
import {ModalContainer, VisuallyHiddenInput} from "../ModalFTE/style";
import {WebSocketContext} from "../../service/WSContext";

const ModalSupplier = ({open, onClose, budget_uuid, modalTitle, currentSuppliers, agency_uuid, currentProject, setCurrentSuppliers}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const handleAddResource = supplier => {
    sendMessageWS({
      method: "update_resource",
      data: {
        kind: 2, //Level
        project_uuid: currentProject.project_uuid,
        supplier_uuid: supplier.uuid,
        budget_uuid: budget_uuid,
      },
    });
    onClose();
  };

  useEffect(() => {
    sendMessageWS({method: "list_suppliers", data: {agency_uuid: agency_uuid}});
  }, []);

  useEffect(() => {
    if (message && message.method == "list_suppliers") {
      setSuppliers(message.data.suppliers);
      setFiltered(message.data.suppliers);
    }
    if (message && message.method == "update_resource" && message.data !== null) {
      onClose();
    }
  }, [message]);

  const handleSearch = e => {
    var originalData = suppliers;

    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.name.toLowerCase().includes(searchString)) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  return (
    <ModalContainer open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          size="small"
          onChange={handleSearch}
          InputProps={{
            startAdornment: <SearchRounded sx={{marginRight: 1}} />,
          }}
        />

        <List className="supplier-list">
          {filtered.map((supplier, i) => {
            return (
              <ListItem
                className="cargo-item"
                key={i}
                onClick={e => {
                  handleAddResource(supplier);
                }}>
                {supplier.name}
              </ListItem>
            );
          })}
        </List>
      </BoxContainer>
    </ModalContainer>
  );
};

export default ModalSupplier;
