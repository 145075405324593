import styled from "styled-components";

const ContainerImpostos = styled.div`
    text-align: center;
    gap: 20px;
    box-sizing: border-box;
    min-width: 40%;
    justify-content: ${props => props.header? 'center': 'end'};
    
    &&, .items-imposto, .item-imposto, .item-total{
        display: flex;
    }

    .item-imposto, .item-total{
        flex-direction: column;
        color: #1976d2;
        align-items: center;
        justify-content: end;
        gap: 5px;

        h4 {
            font-size: 1.2em;
        }
    }

    .item-imposto{
        flex-direction: row;
        color: #8a8a8a !important;
        justify-content: end;
        font-size: .85rem;
        line-height: 1.1rem;
    }

`;

export {ContainerImpostos};
