import styled from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  background-color: #ffffffce;
  border: 1px solid ${colors["outline-border"]};
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;

  .project-value {
    margin-left: auto;
    margin-right: 15px;
  }

  .read-only-input {
    background-color: ${colors["super-light-gray"]};
    border-radius: 4px;
    padding: 10px;
    text-align: center;
  }

  .container-imposto {
    height: 0px;
    transition: all 1s ease;
    overflow: hidden;
  }

  .container-imposto.open {
    height: 70px;
    padding: 5px 0;
  }

  .imposto-title {
    font-size: 1em;
    white-space: nowrap;
    color: ${colors.blue};
  }

  .project-params-container,
  .project-params {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }

  .project-params-container {
    flex-direction: column;
    align-items: start;
    justify-content: start;

    .project-item-total {
      display: none;
      flex-direction: row;
      color: #1976d2;
      align-items: start;
      justify-content: start;
      gap: 5px;
      margin: 0;

      h5,
      h4 {
        font-size: 1.1em;
      }
    }
  }

  @media (max-width: 800px) {
    .project-params-container {

      .project-item-total {
        display: flex;
      }
    }
  }
`;

export {Container};
