import {TableContainer, Table, TableHead, TableRow, TextField, TableCell, Button, Paper, Checkbox, InputAdornment, TableBody} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import ServiceRow from "../ServiceRow/ServiceRow";


const TableServices = ({handleOpenModalServices, services, project_service_total, ...props}) => {


  const styleCell = {
    lineHeight: "1em",
    textAlign: "right",
    whiteWrap: "nowrap",
  };

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible"}}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead
          style={{
            backgroundColor: "#f8f7f7",
          }}>
          <TableRow>
            <TableCell align="left">
              <Button variant="contained" color="primary" onClick={handleOpenModalServices}>
                + SERVIÇO
              </Button>
            </TableCell>

            <TableCell style={styleCell}>Total: R$ {project_service_total}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service, i) => (
            <ServiceRow key={service.resource_uuid} service={service} {...props} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableServices;