import React, {useEffect, useState} from "react";
import {Fab} from "@mui/material";
import {Download, Add, OpenInNew, Autorenew} from "@mui/icons-material";
import ModalUpload from "../../../components/ModalUpload/ModalUpload";
import {modal} from "../../../methods/methods";
import makeRequest from "../../../utils/makeRequest";
import {CartaCell} from "./style";
import getCsrfToken from "../../../utils/getCsrfToken";

const SupplierCarta = ({resource_uuid, supplier}) => {
  const [openModal, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState(supplier.resource_file_path);

  useEffect(() => {
    setFilePath(supplier.resource_file_path);
  }, [supplier]);

  const handleFile = e => {
    setFile(e.target.files[0]);
  };

  const onUpload = data => {
    setProgress(Math.round((100 * data.loaded) / data.total));
  };

  const handleUpload = e => {
    var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/budget/upload_file/`;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("resource_uuid", resource_uuid);

    getCsrfToken().then(res => {
      makeRequest(url, formData, "post", onUpload, res.data)
      .then(response => {
        if (response.status == 200) {
          setOpenModal(false);
          setFilePath(true);
        }
      });
    });
  };

  const handleDownload = e => {
    var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/budget/download_file?resource_uuid=${resource_uuid}`;
    var a = document.createElement("A");
    a.href = url;
    a.download = url.substr(url.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <CartaCell>
      {filePath == null && (
        <Fab
          onClick={() => {
            modal.open(setOpenModal);
          }}
          sx={{width: "30px", height: "30px", minHeight: "20px", fontSize: ".6rem"}}
          size="small"
          color="primary"
          aria-label="add">
          <Add />
        </Fab>
      )}

      {filePath !== null && <Download onClick={handleDownload} />}
      {filePath !== null && (
        <Autorenew
          onClick={e => {
            setFile(null);
            setProgress(0);
            modal.open(setOpenModal);
          }}
        />
      )}

      <ModalUpload
        setFile={setFile}
        file={file}
        progress={progress}
        open={openModal}
        onClose={() => {
          modal.close(setOpenModal);
        }}
        handleUpload={handleUpload}
        handleFile={handleFile}
        modalTitle={"Upload de Carta "}
      />
    </CartaCell>
  );
};

export default SupplierCarta;
