import {Modal, TextField, Button} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "./style";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState, useContext} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../service/WSContext";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import getCookie from "../../../utils/GetCookie";
import SelectInput from "../SelectInput/SelectInput";

const ModalCreateSupplier = ({open, onClose, modalTitle, current}) => {
  const [supplierName, setSupplierName] = useState();
  const [agencyUUID, setAgencyUUID] = useState(getCookie("agencyuuid") ? getCookie("agencyuuid") : "");
  const [agencies, setAgencies] = useState([]);
  const [nameFieldError, setNameFieldError] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [openDialog, setOpenDialog] = useState(false);

  const handleInput = e => {
    if (e.target.name == "agency_uuid") {
      setAgencyUUID(e.target.value);
    } else {
      setSupplierName(e.target.value);
    }
  };

  useEffect(() => {
    if (supplierName == "") {
      setNameFieldError(true);
    } else {
      setNameFieldError(false);
    }
  }, [supplierName]);

  useEffect(() => {
    if (current) {
      if (current.name !== "") {
        setNameFieldError(false);
      }
    }
  }, [current]);

  useEffect(() => {
    setSupplierName("");
    if (open) {
      sendMessageWS({method: "list_agencies"});
    }
  }, [open]);

  useEffect(() => {
    if (message && message.method == "list_agencies") {
      setAgencies(message.data.agencies);
    }
  }, [message]);

  const handleUpdateSupplier = (remove = false) => {
    sendMessageWS({
      method: "update_supplier",
      data: {
        agency_uuid: agencyUUID,
        supplier_name: supplierName,
        supplier_uuid: current ? current.uuid : 0,
        delete_supplier: remove && true,
      },
    });
    onClose();
    setSupplierName("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>
        <TextField
          id="outlined-basic"
          placeholder="Nome do fornecedor"
          label="Nome do fornecedor"
          defaultValue={current && current.name}
          variant="outlined"
          onChange={handleInput}
          error={nameFieldError}
          helperText={nameFieldError ? "Campo obrigatório" : ""}
        />
        <SelectInput
          name="agency_uuid"
          onChange={handleInput}
          id="outlined-basic"
          sx={{flexGrow: 1}}
          variant="outlined"
          label="Agência"
          value={agencyUUID}
          options={agencies}
          inputProps={{readOnly: true}}
          // defaultValue={current && current.area__uuid}
        />
        <ContainerButtons>
          {current && (
            <Button
              onClick={() => {
                setOpenDialog(true);
                setSupplierName("");
              }}
              variant="contained"
              color="error">
              <DeleteOutlineIcon />
            </Button>
          )}
          <Button
            className="main-btn"
            onClick={() => {
              handleUpdateSupplier();
            }}
            variant="contained">
            {current ? "Salvar" : "Criar"}
          </Button>
        </ContainerButtons>

        <DialogConfirmDelete
          subject={"esse recurso"}
          open={openDialog}
          handleDelete={() => handleUpdateSupplier("delete")}
          setDialogDeleteOpen={setOpenDialog}
        />
      </BoxContainer>
    </Modal>
  );
};

export default ModalCreateSupplier;
