import React, {useState} from "react";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {modal} from "../../../methods/methods";
import {HeaderComp} from "./style";
import ModalClausulaBudget from "../ModalClausulaBudget/ModalClausulaBudget";

const ClausulasHeader = ({budget_uuid}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <HeaderComp>
      <h3>Cláusulas do Orçamento</h3>
      <Button
        onClick={() => {
          modal.open(setOpenModal);
        }}
        startIcon={<AddIcon />}
        sx={{width: "fit-content", alignSelf: "center", lineHeight: "2em"}}
        variant="contained">
        CLÁUSULA
      </Button>

      <ModalClausulaBudget
        open={openModal}
        onClose={() => {
          modal.close(setOpenModal);
        }}
        budget_uuid={budget_uuid}
        modalTitle="Adicionar cláusula"
      />
    </HeaderComp>
  );
};

export default ClausulasHeader;
