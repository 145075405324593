import styled from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffffce;
  border: 1px solid #0000001e;
  box-sizing: border-box;
  padding: 15px 20px;
  border-radius: 5px;

  .item-total {
    display: flex;
    flex-direction: column;
    color: #1976d2;
    align-items: center;
    justify-content: end;
    gap: 5px;
    min-width: 40%;

    h4 {
      font-size: 1.2em;
    }
  }

  .container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 20px;
  }

  .edit-icon {
    cursor: pointer;
  }

  .container-infos {
    min-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border-right: 1px solid #0000001e;
    padding-right: 10px;
    box-sizing: border-box;
    gap: 5px;
  }

  .control-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;

    div{
      display: flex;
      gap: 10px;
    }

    svg {
      fill: #000000be;
    }
  }

  .title-info {
    margin-bottom: 5px;
  }

  .value-info {
    cursor: pointer;
  }

  .total-budget .total {
    font-size: 0.9rem;
  }

  .total-budget .value {
    font-size: 1.3rem;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 10px;
    align-items: start;
    gap: 10px;

    .container-infos {
      border: none;
      padding: 0;
      min-width: 100%;
    }

    .total-budget {
      justify-content: start;
      flex-direction: row;
      gap: 10px;
    }

    .control-bar {
    }

    .item-total {
      flex-direction: row;
      color: #1976d2;
      align-items: end;
      justify-content: end;
      gap: 5px;
      min-width: 40%;

      h5, h4 {
        font-size: 1.2em;
      }
    }
  }
`;

export {Container};
