import React, {useEffect, useContext, useState} from "react";
import {Container} from "./style";
import {Create, PersonAddAltSharp, Download} from "@mui/icons-material";
import DialogConfirmDelete from "../DialogConfirmDelete/DialogConfirmDelete";
import {table, modal, methods} from "../../../methods/methods";
import {StatusAprovacao} from "../StatusAprovacao/StatusAprovacao";
import {WebSocketContext} from "../../service/WSContext";
import {useNavigate} from "react-router-dom";
import PDFGenerator from "../../service/DownloadBudget/PDFGenerator";
import ModalPermissions from "../ModalPermissions/ModalPermissions";
import ModalBudget from "../ModalBudget/ModalBudget";
import clausulas from "../../../utils/clausulas";
import ModalBudgetStatus from "../ModalBudgetStatus/ModalBudgetStatus";
import getCookie from "../../../utils/GetCookie";
  
const InfosOrcamento = ({budget, opencardproject, setopencardproject}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const [openModalPermission, setOpenModalPermission] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [clausulas, setClausulas] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const navigate = useNavigate();
  
  const sysaccess = window.sessionStorage.getItem("sysaccess")
 
  const handleDeleteBudget = () => {
    sendMessageWS({
      method: "update_budget",
      data: {
        budget_uuid: budget.budget_uuid,
        agency_uuid: budget.budget_agency_uuid,
        delete_budget: true,
      },
    });
    window.location.href = "/budgets/orcamentos";
  };

  const handleEdit = () => {
    modal.open(setEditModalOpen);
  };
  const handleEditStatus = () => {
    modal.open(setStatusModalOpen);
  };

  useEffect(() => {
    sendMessageWS({
      method: "list_clausulabudgets",
      data: {budget_uuid: budget.budget_uuid},
    });
  }, []);

  useEffect(() => {
    if (
      (message && message.method == "list_clausulabudgets") ||
      (message && message.method == "update_add_clausulabudget") ||
      (message && message.method == "update_clausulabudget")
    ) {
      setClausulas(message.data.clausulas);
    }
  }, [message]);

  return (
    <Container>

      <div className="container-infos">
        <div className="control-bar">
          <div>
            <StatusAprovacao onClick={handleEditStatus} status={budget.budget_status}>STATUS: {budget.budget_status_label} </StatusAprovacao>

          </div>
          <div>
            {!opencardproject && <Create fontSize="small" className="edit-icon" onClick={handleEdit} />}
            {!opencardproject && (
              <PDFGenerator budgetData={budget} clausulas={clausulas} children={<Download fontSize="small" className="edit-icon" />} />
            )}
            {!opencardproject && (
              <PersonAddAltSharp
                onClick={() => {
                  modal.open(setOpenModalPermission);
                }}
                fontSize="small"
                className="edit-icon"
              />
            )}
          </div>
        </div>

        <div className="container-title">
          <h3
            className="value-info"
            onClick={e => {
              setopencardproject(false);
            }}>
            {budget.budget_agency_name} / {budget.budget_brand ? budget.budget_brand : `Cliente`} /{" "}
            {budget.budget_name ? budget.budget_name : `Nome do Orçamento`}
          </h3>
        </div>
      </div>

      <div className="item-total">
        <h5>TOTAL</h5>
        <h4>{table.numberToReais(budget.budget_total)}</h4>
      </div>

      {editModalOpen && (
        <ModalBudget
          budget={budget}
          open={editModalOpen}
          onClose={e => {
            modal.close(setEditModalOpen);
          }}
          modalTitle={"Editar orçamento"}
          edit={"true"}
          setDialogDeleteOpen={setDialogDeleteOpen}
        />
      )}
      <ModalBudgetStatus 
        budget={budget}
        sysaccess={sysaccess} 
        open={statusModalOpen}
        onClose={e => {
          modal.close(setStatusModalOpen);
        }} />

      <ModalPermissions
        budget={budget}
        open={openModalPermission}
        onClose={() => {
          modal.close(setOpenModalPermission);
        }}
      />

      <DialogConfirmDelete
        subject={"esse orçamento"}
        open={dialogDeleteOpen}
        handleDelete={handleDeleteBudget}
        setDialogDeleteOpen={setDialogDeleteOpen}
      />
    </Container>
  );
};

export default InfosOrcamento;
