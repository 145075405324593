import styled from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
  padding: 20px 0;
  box-sizing: border-box;

  .card-projeto {
    width: 30%;
    min-width: 300px;
    cursor: pointer;
  }

  .card-projeto:hover {
    background-color: #80808041;
  }

  .card-button {
    width: 30%;
    min-height: 100px;
  }

  .card-title {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${colors["outline-border"]};
    text-transform: capitalize;
  }

  h6 {
    margin-bottom: 5px;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .card-projeto,
    .card-button {
      width: 100%;
    }
  }
`;

export {Container};
